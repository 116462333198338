.post-card {
  background-color: #ffffff;
  border-radius: 12px;
  overflow: hidden;
  transition: all 0.3s ease;
  position: relative;
  display: flex;
  flex-direction: column;
  height: 420px; /* ارتفاع ثابت للكارت */
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  margin-bottom: 20px;
}

.post-card.pinned {
  border: 2px solid var(--primary-color);
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.15);
}

.post-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.15);
}

.light-mode .post-card {
  background-color: #ffffff;
}

.dark-mode .post-card {
  background-color: #4b4b4b;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.post-image {
  display: block;
  position: relative;
  width: 100%;
  height: 200px; /* ارتفاع ثابت للصورة */
  overflow: hidden;
  background-color: #f0f0f0;
}

.post-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.5s ease;
}

.post-card:hover .post-image img {
  transform: scale(1.05);
}

.post-image::after {
  content: '';
  position: absolute;
  bottom: 0;
  left: 0;
  right: 0;
  height: 40px;
  background: linear-gradient(to top, rgba(255, 255, 255, 0.8), transparent);
  z-index: 1;
}

.dark-mode .post-image::after {
  background: linear-gradient(to top, rgba(75, 75, 75, 0.8), transparent);
}

.post-content {
  padding: 20px;
  display: flex;
  flex-direction: column;
  flex-grow: 1;
}

.post-title {
  margin-bottom: 10px;
  font-size: 1.1rem;
  line-height: 1.4;
  font-weight: 700;
  max-height: 3.1em; /* ارتفاع سطرين بالضبط */
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 2; /* عدد الأسطر محدد بسطرين فقط */
  line-clamp: 2; /* خاصية قياسية للتوافق */
  -webkit-box-orient: vertical;
  word-break: break-word; /* تأكيد كسر الكلمات الطويلة */
}

.post-title a {
  text-decoration: none;
  transition: color 0.3s;
}

.light-mode .post-title a {
  color: #1a1a1a;
}

.dark-mode .post-title a {
  color: #f5f5f5;
}

.light-mode .post-title a:hover {
  color: #1E90FF;
}

.dark-mode .post-title a:hover {
  color: #40E0D0;
}

.post-date {
  color: #777;
  font-size: 0.85rem;
  margin-bottom: 10px;
  display: block;
}

.dark-mode .post-date {
  color: #aaa;
}

.post-excerpt {
  margin-bottom: 10px;
  line-height: 1.5;
  font-size: 0.9rem;
  overflow: hidden;
  text-overflow: ellipsis;
  display: -webkit-box;
  -webkit-line-clamp: 3; /* عدد الأسطر محدد بثلاثة أسطر */
  line-clamp: 3; /* خاصية قياسية للتوافق */
  -webkit-box-orient: vertical;
  flex-grow: 1;
}

.light-mode .post-excerpt {
  color: #333;
}

.dark-mode .post-excerpt {
  color: #ddd;
}

.read-more {
  margin-top: auto; /* دفع الزر إلى الأسفل */
  display: block;
  font-weight: 600;
  text-decoration: none;
  font-size: 0.9rem;
  padding: 8px 15px;
  border-radius: 20px;
  transition: all 0.3s ease;
  text-align: center;
  width: fit-content;
  align-self: center; /* توسيط الزر */
}

.light-mode .read-more {
  color: #1E90FF;
  background-color: rgba(30, 144, 255, 0.1);
}

.dark-mode .read-more {
  color: #40E0D0;
  background-color: rgba(64, 224, 208, 0.1);
}

.light-mode .read-more:hover {
  color: white;
  background-color: rgba(30, 144, 255, 0.8);
}

.dark-mode .read-more:hover {
  color: #1a1a1a;
  background-color: rgba(64, 224, 208, 0.8);
}

.pin-indicator {
  position: absolute;
  top: 10px;
  right: 10px;
  background: var(--primary-color);
  color: white;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 2;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.2);
}

.pin-indicator svg {
  transform: rotate(45deg);
}

/* الإعدادات الخاصة بالشاشات الصغيرة */
@media (max-width: 768px) {
  .post-card {
    height: 380px;
  }

  .post-image {
    height: 160px;
  }
  
  .post-content {
    padding: 15px;
  }
  
  .post-title {
    font-size: 1rem;
  }
  
  .post-excerpt {
    font-size: 0.85rem;
    -webkit-line-clamp: 3;
    line-clamp: 3;
  }
}
