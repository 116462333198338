/* أنماط صفحة المسؤول */
.admin-page {
  padding: 0;
  margin: 0;
  text-align: right;
  overflow-x: hidden;
  position: relative;
  max-width: 100%;
  background-color: #f5f5f5; /* لون خلفية رمادي فاتح للصفحة بأكملها في الوضع النهاري */
  min-height: 100vh;
  font-family: 'Cairo', sans-serif;
  direction: rtl;
  overflow-y: auto;
}

/* الوضع الداكن للصفحة بأكملها */
.dark-mode.admin-page {
  background-color: #1a1a1a;
  color: #f0f0f0;
}

/* تصميم الشريط الجانبي بشكل دائري وعائم */
.admin-sidebar {
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #F0F9FF;
  border-radius: 70px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.1);
  padding: 25px;
  position: fixed;
  top: 50%;
  right: 30px;
  transform: translateY(-50%);
  z-index: 100;
  width: auto;
  max-width: 260px;
}

.dark-mode .admin-sidebar {
  background-color: #2a2a2a;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.3);
}

.admin-logo-container {
  text-align: center;
  padding: 15px 0;
  margin-bottom: 10px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.admin-logo-img {
  max-width: 120px;
  height: auto;
  transition: all 0.3s ease;
}

.collapsed .admin-logo-img {
  max-width: 40px;
  margin: 0 auto;
}

/* إخفاء الشعار النصي السابق */
.admin-logo {
  display: none;
}

.admin-tabs {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: -20px;
}

.admin-tab {
  padding: 12px 15px;
  cursor: pointer;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  color: var(--sidebarText);
  position: relative;
}

.admin-tab:hover {
  background-color: transparent;
  color: #0066cc;
  transform: translateY(-2px);
  border-radius: 100px;
  box-shadow: 0 0 0 2px #0066cc;
}

.admin-tab.active {
  background-color: #0066cc;
  color: white;
  border-radius: 100px;
  box-shadow: none;
}

/* تصميم زر إنشاء منشور جديد */
.admin-tab:first-of-type {
  background-color: transparent;
  color: #333;
  font-weight: bold;
  margin-bottom: 15px;
  border: none;
}

.admin-tab:first-of-type:hover {
  background-color: transparent;
  color: #0066cc;
  box-shadow: 0 0 0 2px #0066cc;
}

.admin-tab:first-of-type.active {
  background-color: #0066cc;
  color: white;
  box-shadow: none;
}

/* الوضع الليلي */
.dark-mode .admin-tab {
  color: #e0e0e0;
  background-color: transparent;
}

.dark-mode .admin-tab:hover {
  background-color: transparent;
  color: #00c3ad;
  box-shadow: 0 0 0 2px #00c3ad;
}

.dark-mode .admin-tab.active {
  background-color: #00c3ad;
  color: #ffffff;
  box-shadow: none;
}

.dark-mode .admin-tab:first-of-type {
  background-color: transparent;
  color: #e0e0e0;
  border: none;
}

.dark-mode .admin-tab:first-of-type:hover {
  background-color: transparent;
  color: #00c3ad;
  box-shadow: 0 0 0 2px #00c3ad;
}

.dark-mode .admin-tab:first-of-type.active {
  background-color: #00c3ad;
  color: #ffffff;
  box-shadow: none;
}

.admin-tab svg {
  margin-left: 10px;
  width: 18px;
  height: 18px;
}

/* تعديل موضع المحتوى الرئيسي ليناسب الشريط الجانبي العائم */
.admin-content {
  flex: 1;
  padding: 30px;
  margin-right: 350px; /* مسافة من جانب لوحة الأزرار */
  margin-left: 50px; /* مسافة من الجانب الأيسر */
  width: calc(100% - 450px); /* تعديل العرض ليناسب المساحة المتاحة */
  max-width: none;
  text-align: right;
  direction: rtl;
  box-sizing: border-box;
  position: relative;
}

/* تعديل اتجاه العناصر ليناسب RTL */
.admin-tab {
  direction: rtl;
  text-align: right;
}

@media (max-width: 768px) {
  .admin-sidebar {
    position: fixed;
    top: auto;
    bottom: 20px;
    right: 20px;
    transform: none;
    width: auto;
    max-width: none;
    border-radius: 50px;
    padding: 15px;
    flex-direction: row;
    justify-content: center;
    max-width: 400px;
  }
  
  .admin-logo {
    display: none;
  }
  
  .admin-tabs {
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
    gap: 10px;
  }
  
  .admin-tab {
    margin-bottom: 0;
    padding: 10px;
    width: auto;
    border-radius: 25px;
    justify-content: center;
  }
  
  .tab-title {
    display: none;
  }
  
  .admin-tab svg {
    margin: 0;
    width: 20px;
    height: 20px;
  }
  
  .admin-content {
    margin-right: 0;
    padding: 15px;
    width: 100%;
  }
  
  .admin-tab .drag-handle {
    display: none;
  }
}

/* تنسيق لوحة المسؤول */
.admin-container {
  display: flex;
  flex-direction: row-reverse;
  width: 100%;
  max-width: 100%;
  position: relative;
  min-height: 100vh;
  margin: 0;
  padding: 0;
  align-items: flex-start;
}

/* تنسيق الشريط الجانبي */
.admin-sidebar {
  display: flex;
  flex-direction: column;
  width: 280px;
  background-color: #ffffff;
  border-left: 1px solid rgba(0, 0, 0, 0.1);
  padding: 2rem 1rem;
  transition: all 0.3s ease;
  box-shadow: -1px 0 10px rgba(0, 0, 0, 0.05);
}

.dark-mode .admin-sidebar {
  background-color: #222222;
  border-left-color: rgba(255, 255, 255, 0.1);
  box-shadow: -1px 0 10px rgba(0, 0, 0, 0.2);
}

.admin-logo {
  font-size: 1.5rem;
  color: #1E90FF;
  margin-bottom: 2rem;
  text-align: right;
}

.dark-mode .admin-logo {
  color: #40E0D0;
}

/* تنسيق أزرار التنقل */
.admin-nav {
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
}

.admin-nav-button {
  display: flex;
  align-items: center;
  padding: 1rem;
  border-radius: 10px;
  border: none;
  background-color: transparent;
  color: #555;
  cursor: pointer;
  transition: all 0.3s ease;
  text-align: right;
  font-family: 'Cairo', sans-serif;
  font-size: 1rem;
  font-weight: 600;
}

.admin-nav-button:hover {
  background-color: rgba(30, 144, 255, 0.1);
  color: #1E90FF;
}

.dark-mode .admin-nav-button {
  color: #ccc;
}

.dark-mode .admin-nav-button:hover {
  background-color: rgba(64, 224, 208, 0.1);
  color: #40E0D0;
}

.admin-nav-button.active {
  background-color: rgba(30, 144, 255, 0.15);
  color: #1E90FF;
  font-weight: 600;
  box-shadow: 0 2px 10px rgba(30, 144, 255, 0.2);
}

.dark-mode .admin-nav-button.active {
  background-color: rgba(64, 224, 208, 0.15);
  color: #40E0D0;
  box-shadow: 0 2px 10px rgba(64, 224, 208, 0.2);
}

.nav-icon {
  margin-left: 0.75rem;
  font-size: 1.1rem;
}

/* تنسيق محتوى اللوحة */
.admin-content {
  flex: 1;
  padding: 2rem;
  overflow-y: auto;
  max-width: none;
  text-align: right;
  direction: rtl;
  box-sizing: border-box;
  position: relative;
  margin-right: 350px; margin-left: 50px; width: calc(100% - 450px);
}

.admin-panel-section {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
  padding: 30px;
  margin-bottom: 30px;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  position: relative;
  display: block;
  clear: both;
}

.dark-mode .admin-panel-section {
  background-color: #2a2a2a;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.admin-panel-section h2 {
  margin-top: 0;
  margin-bottom: 25px;
  color: var(--primary-color);
  font-size: 1.8rem;
  font-weight: 800;
  border-bottom: 1px solid #eee;
  padding-bottom: 15px;
  display: block;
  width: 100%;
}

.dark-mode .admin-panel-section h2 {
  border-bottom-color: #333;
}

.admin-panel-section h2::before {
  display: none;
}

.admin-panel-section h3 {
  margin-top: 1.5rem;
  margin-bottom: 1rem;
  color: #444;
  font-size: 1.3rem;
}

.dark-mode .admin-panel-section h3 {
  color: #e0e0e0;
}

/* لوحة الإحصائيات */
.dashboard-stats {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 1.5rem;
  margin-top: 1.5rem;
}

.stat-card {
  background-color: #f9f9fa;
  border-radius: 10px;
  padding: 1.5rem;
  text-align: center;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  border-top: 3px solid #1E90FF;
}

.dark-mode .stat-card {
  background-color: #333;
  border-top-color: #40E0D0;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.stat-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
}

.dark-mode .stat-card:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.stat-card h3 {
  margin-top: 0;
  margin-bottom: 0.5rem;
  color: #555;
  font-size: 1.1rem;
}

.dark-mode .stat-card h3 {
  color: #ccc;
}

.stat-card p {
  margin: 0;
  font-size: 2rem;
  font-weight: bold;
  color: #1E90FF;
}

.dark-mode .stat-card p {
  color: #40E0D0;
}

/* تنسيق النماذج */
.form-group {
  margin-bottom: 1.5rem;
}

.form-group label {
  display: block;
  margin-bottom: 0.5rem;
  color: #444;
  font-weight: 600;
}

.dark-mode .form-group label {
  color: #e0e0e0;
}

.form-input,
.form-select {
  width: 100%;
  padding: 12px 15px;
  border-radius: 8px;
  border: 1px solid #ddd;
  background-color: #fff;
  font-family: 'Cairo', sans-serif;
  font-size: 1rem;
  transition: all 0.3s ease;
  color: #333;
}

.dark-mode .form-input,
.dark-mode .form-select {
  background-color: #333;
  border-color: #555;
  color: #f0f0f0;
}

.form-input:focus,
.form-select:focus {
  outline: none;
  border-color: #1E90FF;
  box-shadow: 0 0 0 2px rgba(30, 144, 255, 0.2);
}

.dark-mode .form-input:focus,
.dark-mode .form-select:focus {
  border-color: #40E0D0;
  box-shadow: 0 0 0 2px rgba(64, 224, 208, 0.2);
}

/* تنسيق أزرار الإجراءات */
.action-button {
  padding: 10px 18px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  font-family: 'Cairo', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  transition: all 0.3s ease;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  margin-right: 8px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.action-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255,255,255,0.1), rgba(255,255,255,0.2));
  transform: translateX(-100%);
  transition: transform 0.4s ease;
}

.action-button:hover:before {
  transform: translateX(0);
}

.action-button:active {
  transform: translateY(2px);
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
}

.dark-mode .action-button {
  background-color: #00CED1;
  color: #222;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.dark-mode .action-button:hover {
  background-color: #3bc9bb;
}

.dark-mode .action-button:active {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

/* تنسيق زر الإرسال */
.submit-button {
  background-color: #0066cc !important; /* تغيير اللون الافتراضي إلى أزرق غامق */
  color: white;
  padding: 12px 30px;
  border-radius: 50px;
  border: none;
  cursor: pointer;
  font-family: 'Cairo', sans-serif;
  font-size: 1.1rem;
  font-weight: 600;
  transition: all 0.3s ease;
  margin-top: 1.5rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.1);
  position: relative;
  overflow: hidden;
}

.submit-button:before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(to right, rgba(255,255,255,0.1), rgba(255,255,255,0.2));
  transform: translateX(-100%);
  transition: transform 0.4s ease;
}

.submit-button:hover:before {
  transform: translateX(0);
}

.submit-button:active {
  transform: translateY(2px);
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.dark-mode .submit-button {
  background-color: #00CED1;
  color: #222;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.3);
}

.dark-mode .submit-button:hover {
  background-color: #3bc9bb;
}

.dark-mode .submit-button:active {
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.3);
}

/* أنماط المخططات البيانية */
.chart-placeholder {
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.chart-bars {
  display: flex;
  align-items: flex-end;
  height: 200px;
  width: 100%;
  gap: 8px;
  padding: 0 10px;
}

.chart-bar {
  flex: 1;
  background: linear-gradient(180deg, #1E90FF, #4169E1);
  border-radius: 6px 6px 0 0;
  transition: all 0.3s ease;
  position: relative;
  min-width: 15px;
}

.dark-mode .chart-bar {
  background: linear-gradient(180deg, #00CED1, #40E0D0);
}

.chart-bar:hover {
  opacity: 0.9;
  transform: scaleY(1.03);
}

.dark-mode .chart-bar:hover {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.3);
}

.chart-label {
  position: absolute;
  bottom: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8rem;
  color: #666;
}

.dark-mode .chart-label {
  color: #aaa;
}

.chart-value {
  position: absolute;
  top: -25px;
  left: 50%;
  transform: translateX(-50%);
  font-size: 0.8rem;
  color: #1E90FF;
  font-weight: 600;
}

.dark-mode .chart-value {
  color: #40E0D0;
}

/* تصميم العناصر القابلة للسحب */
.drag-handle {
  cursor: grab;
  margin-left: 8px;
  color: var(--text-tertiary);
  display: inline-flex;
  align-items: center;
}

.admin-tab {
  position: relative;
  display: flex;
  align-items: center;
  padding: 12px 16px;
  margin-bottom: 5px;
  border-radius: 8px;
  transition: all 0.3s ease;
  cursor: pointer;
  user-select: none;
}

.admin-tab.dragging {
  opacity: 0.5;
  transform: scale(1.05);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
  z-index: 100;
}

.admin-tab.drag-over {
  border: 2px dashed var(--primary-color);
  background-color: rgba(var(--primary-rgb), 0.1);
}

.sortable-item {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 12px 16px;
  margin-bottom: 8px;
  background-color: var(--bg-secondary);
  border-radius: 8px;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  cursor: grab;
  user-select: none;
}

.sortable-item.dragging {
  opacity: 0.7;
  transform: scale(1.02);
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.1);
  z-index: 100;
}

.sortable-item.drag-over {
  border: 2px dashed var(--primary-color);
  padding: 10px 14px; /* تعديل التباعد ليتناسب مع إضافة الحدود */
}

.drag-instruction {
  margin-bottom: 16px;
  color: var(--text-secondary);
  font-size: 14px;
}

/* تحسينات لأقسام السحب المختلفة */
.tab-preview, .category-preview {
  display: flex;
  align-items: center;
  flex-grow: 1;
}

.tab-icon {
  margin-left: 8px;
}

.item-name, .category-name {
  font-weight: 500;
}

.item-visibility {
  display: flex;
  align-items: center;
}

.visibility-label {
  margin-right: 8px;
  font-size: 14px;
  color: var(--text-secondary);
}

/* تصميم التبديل */
.toggle-switch {
  position: relative;
  display: inline-block;
  width: 40px;
  height: 20px;
}

.toggle-switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #0066cc !important;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0066cc !important;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.dark-mode .slider {
  background-color: #4b4b4b !important;
}

.dark-mode .slider:before {
  background-color: white !important;
}

.dark-mode input:checked + .slider {
  background-color: #00c3ad !important;
}

.dark-mode input:focus + .slider {
  box-shadow: 0 0 1px #00c3ad !important;
}

/* تحديث مفاتيح التبديل في الوضع الليلي */
.dark-mode input:checked + .slider {
  background-color: #00c3ad !important;
}

.dark-mode input:focus + .slider {
  box-shadow: 0 0 1px #00c3ad !important;
}

/* تأكيد استخدام الخط العربي */
.admin-page,
.admin-logo,
.admin-nav-button,
.form-input,
.form-select,
.action-button,
.submit-button,
h1, h2, h3, h4, h5, h6,
p, span, label, button, input {
  font-family: 'Cairo', sans-serif;
}

/* تنسيقات للشاشات الصغيرة */
@media (max-width: 768px) {
  .admin-container {
    flex-direction: column;
  }
  
  .admin-sidebar {
    width: 100%;
    border-left: none;
    border-bottom: 1px solid rgba(0, 0, 0, 0.1);
    padding: 1rem;
  }
  
  .dark-mode .admin-sidebar {
    border-bottom-color: rgba(255, 255, 255, 0.1);
  }
  
  .admin-nav {
    flex-direction: row;
    overflow-x: auto;
    padding-bottom: 0.5rem;
  }
  
  .admin-nav-button {
    flex-shrink: 0;
  }
  
  .form-row {
    flex-direction: column;
  }
  
  .dashboard-stats {
    grid-template-columns: 1fr;
  }
  
  .admin-content {
    margin-right: 0;
    padding: 15px;
    width: 100%;
  }
  
  .admin-tab .drag-handle {
    display: none;
  }
}

/* تصميم قسم API */
.api-container {
  display: flex;
  flex-direction: column;
  gap: 30px;
  padding: 20px;
  background-color: var(--bg-secondary);
  border-radius: 15px;
  margin-top: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.dark-mode .api-container {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.api-description {
  background-color: rgba(var(--primary-rgb), 0.1);
  padding: 20px;
  border-radius: 12px;
  border-right: 4px solid var(--primary-color);
}

.api-key-section {
  background-color: #f9f9f9;
  padding: 20px;
  border-radius: 12px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.dark-mode .api-key-section {
  background-color: #2a2a2a;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.2);
}

.api-key-container {
  display: flex;
  gap: 10px;
  margin: 15px 0;
}

.api-key-input {
  flex: 1;
  padding: 12px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  direction: ltr;
  text-align: left;
  font-family: monospace;
  background-color: #f5f5f5;
}

.dark-mode .api-key-input {
  background-color: #333;
  border-color: #444;
  color: #eee;
}

.copy-button, .refresh-button {
  padding: 8px 15px;
  border: none;
  border-radius: 8px;
  background-color: var(--primary-color);
  color: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
}

.refresh-button {
  background-color: #6c757d;
}

.copy-button:hover, .refresh-button:hover {
  opacity: 0.9;
  transform: translateY(-2px);
}

.api-warning {
  color: #e74c3c;
  font-size: 14px;
  margin-top: 10px;
}

.api-documentation {
  background-color: #fff;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
}

.dark-mode .api-documentation {
  background-color: #2a2a2a;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.api-endpoint, .api-method, .api-params, .api-example, .api-response {
  margin-bottom: 25px;
}

.api-documentation h4 {
  margin-bottom: 10px;
  font-size: 18px;
  color: var(--primary-color);
}

.api-documentation code {
  display: block;
  padding: 12px 15px;
  border-radius: 8px;
  background-color: #f5f5f5;
  font-family: monospace;
  direction: ltr;
  text-align: left;
  border: 1px solid #ddd;
  overflow-x: auto;
}

.dark-mode .api-documentation code {
  background-color: #333;
  border-color: #444;
  color: #f5f5f5;
}

.api-table {
  width: 100%;
  border-collapse: collapse;
  direction: rtl;
}

.api-table th, .api-table td {
  padding: 12px 15px;
  text-align: right;
  border-bottom: 1px solid #ddd;
}

.dark-mode .api-table th, .dark-mode .api-table td {
  border-color: #444;
}

.api-table th {
  background-color: rgba(var(--primary-rgb), 0.1);
}

.dark-mode .api-table th {
  background-color: rgba(var(--primary-rgb), 0.2);
}

.code-example {
  white-space: pre-wrap;
  font-family: monospace;
  background-color: #f5f5f5;
  padding: 15px;
  border-radius: 8px;
  direction: ltr;
  text-align: left;
  border: 1px solid #ddd;
  overflow-x: auto;
}

.dark-mode .code-example {
  background-color: #333;
  border-color: #444;
  color: #f5f5f5;
}

.test-api-section {
  background-color: #f9f9f9;
  padding: 25px;
  border-radius: 12px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
}

.dark-mode .test-api-section {
  background-color: #2a2a2a;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.test-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-textarea {
  width: 100%;
  min-height: 150px;
  padding: 12px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  resize: vertical;
  direction: rtl;
}

.dark-mode .form-textarea {
  background-color: #333;
  border-color: #444;
  color: #f5f5f5;
}

/* أنماط محرر Quill */
.editor-container {
  margin-bottom: 20px;
  border-radius: 8px;
  overflow: hidden;
  background: #fff;
}

.dark-mode .editor-container {
  background: #2a2a2a;
}

.editor-container .quill {
  height: 400px;
  direction: rtl;
  text-align: right;
}

.dark-mode .editor-container .quill {
  border-color: #444;
}

.dark-mode .ql-toolbar,
.dark-mode .ql-container {
  background-color: #333;
  border-color: #444;
  color: #f5f5f5;
}

.dark-mode .ql-editor {
  color: #f5f5f5;
}

.dark-mode .ql-editor.ql-blank::before {
  color: #aaa;
}

.dark-mode .ql-snow .ql-stroke {
  stroke: #f5f5f5;
}

.dark-mode .ql-snow .ql-fill, .dark-mode .ql-snow .ql-stroke.ql-fill {
  fill: #f5f5f5;
}

.dark-mode .ql-picker-label {
  color: #f5f5f5;
}

/* تعديل اتجاه القوائم المنسدلة في المحرر */
.editor-container .ql-snow .ql-picker.ql-header .ql-picker-label,
.editor-container .ql-snow .ql-picker.ql-size .ql-picker-label,
.editor-container .ql-snow .ql-picker.ql-font .ql-picker-label {
  padding-right: 8px;
  padding-left: 20px;
}

.editor-container .ql-snow .ql-picker.ql-header .ql-picker-label::before,
.editor-container .ql-snow .ql-picker.ql-size .ql-picker-label::before,
.editor-container .ql-snow .ql-picker.ql-font .ql-picker-label::before {
  right: auto;
  left: 0;
}

.editor-container .ql-snow .ql-picker-options {
  text-align: right;
}

/* تصميم قسم النشرة البريدية */
.newsletter-section {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(350px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.newsletter-stats {
  background-color: #f9f9f9;
  border-radius: 12px;
  padding: 25px;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
}

.dark-mode .newsletter-stats {
  background-color: #2a2a2a;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.2);
}

.stat-box {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
  padding: 15px;
  border-radius: 8px;
  background-color: #fff;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.dark-mode .stat-box {
  background-color: #333;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.stat-icon {
  width: 50px;
  height: 50px;
  border-radius: 50%;
  background-color: rgba(var(--primary-rgb), 0.1);
  display: flex;
  align-items: center;
  justify-content: center;
  color: var(--primary-color);
}

.stat-info h4 {
  margin: 0;
  font-size: 16px;
  color: var(--text-secondary);
}

.stat-info p {
  margin: 5px 0 0;
  font-size: 24px;
  font-weight: bold;
}

/* تصميم صفحة إنشاء منشور جديد بالتصميم الحديث */
.modern-create-post {
  background-color: var(--bg-secondary);
  border-radius: 15px;
  padding: 30px;
  margin-top: 20px;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.dark-mode .modern-create-post {
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.post-creation-grid {
  display: grid;
  grid-template-columns: 2fr 1fr;
  gap: 30px;
}

.post-main-content {
  display: flex;
  flex-direction: column;
  gap: 25px;
}

.post-settings-sidebar {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.settings-card {
  background-color: #fff;
  border-radius: 12px;
  padding: 20px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.dark-mode .settings-card {
  background-color: #2a2a2a;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}

.post-form-group {
  margin-bottom: 20px;
}

.post-form-group label {
  display: block;
  margin-bottom: 8px;
  font-weight: 600;
  font-size: 15px;
}

.modern-input, .modern-select, .modern-textarea {
  width: 100%;
  padding: 12px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.2s ease;
}

.dark-mode .modern-input, 
.dark-mode .modern-select, 
.dark-mode .modern-textarea {
  background-color: #333;
  border-color: #444;
  color: #f5f5f5;
}

.modern-input:focus, 
.modern-select:focus, 
.modern-textarea:focus {
  border-color: var(--primary-color);
  box-shadow: 0 0 0 2px rgba(var(--primary-rgb), 0.2);
  outline: none;
}

.modern-textarea {
  resize: vertical;
  min-height: 100px;
}

.editor-container {
  border-radius: 8px;
  overflow: hidden;
}

.editor-container .quill {
  border-radius: 8px;
  border: 1px solid #ddd;
  direction: rtl;
}

.dark-mode .editor-container .quill {
  border-color: #444;
}

.dark-mode .ql-toolbar,
.dark-mode .ql-container {
  background-color: #333;
  border-color: #444;
  color: #f5f5f5;
}

.dark-mode .ql-editor {
  color: #f5f5f5;
}

.dark-mode .ql-editor.ql-blank::before {
  color: #aaa;
}

.dark-mode .ql-snow .ql-stroke {
  stroke: #f5f5f5;
}

.dark-mode .ql-snow .ql-fill, .dark-mode .ql-snow .ql-stroke.ql-fill {
  fill: #f5f5f5;
}

.dark-mode .ql-picker-label {
  color: #f5f5f5;
}

.featured-image-uploader {
  border: 2px dashed #ddd;
  border-radius: 8px;
  padding: 20px;
}

.dark-mode .featured-image-uploader {
  border-color: #444;
}

.upload-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  min-height: 200px;
  cursor: pointer;
}

.file-input {
  display: none;
}

.upload-label {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 15px;
  cursor: pointer;
}

.upload-label svg {
  font-size: 50px;
  color: #ccc;
}

.dark-mode .upload-label svg {
  color: #555;
}

.upload-label span {
  text-align: center;
  color: #999;
}

.image-preview-container {
  position: relative;
  width: 100%;
}

.image-preview {
  width: 100%;
  height: auto;
  border-radius: 8px;
  max-height: 300px;
  object-fit: cover;
}

.remove-image-btn {
  position: absolute;
  top: 10px;
  right: 10px;
  width: 30px;
  height: 30px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.5);
  color: white;
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.2s ease;
}

.remove-image-btn:hover {
  background-color: rgba(0, 0, 0, 0.7);
}

.tags-input-container {
  border: 1px solid #ddd;
  border-radius: 8px;
  padding: 8px;
  background-color: #fff;
}

.dark-mode .tags-input-container {
  background-color: #333;
  border-color: #444;
}

.tag-input {
  width: 100%;
  padding: 8px 10px;
  border: none;
  outline: none;
  font-size: 14px;
}

.dark-mode .tag-input {
  background-color: #333;
  color: #f5f5f5;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
  margin-top: 10px;
}

.tag {
  background-color: rgba(var(--primary-rgb), 0.1);
  color: var(--primary-color);
  padding: 5px 10px;
  border-radius: 25px;
  font-size: 14px;
  display: inline-flex;
  align-items: center;
  gap: 5px;
}

.remove-tag {
  width: 18px;
  height: 18px;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.1);
  border: none;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  cursor: pointer;
  color: var(--primary-color);
}

.dark-mode .remove-tag {
  background-color: rgba(255, 255, 255, 0.2);
}

.input-hint {
  font-size: 12px;
  color: #999;
  margin-top: 5px;
}

.dark-mode .input-hint {
  color: #777;
}

.schedule-options {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
}

.radio-option {
  display: flex;
  align-items: center;
  gap: 8px;
}

.schedule-datetime {
  margin-top: 10px;
}

.toggle-option {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Toggle Switch Styling */
.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc;
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white;
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #0066cc !important;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0066cc !important;
}

input:checked + .slider:before {
  transform: translateX(26px);
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.dark-mode .slider {
  background-color: #4b4b4b !important;
}

.dark-mode .slider:before {
  background-color: white !important;
}

.dark-mode input:checked + .slider {
  background-color: #00c3ad !important;
}

.dark-mode input:focus + .slider {
  box-shadow: 0 0 1px #00c3ad !important;
}

/* تحديث مفاتيح التبديل للتعليقات وتثبيت المنشور */
.toggle-option {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 15px;
}

.switch {
  position: relative;
  display: inline-block;
  width: 50px;
  height: 24px;
}

.switch input {
  opacity: 0;
  width: 0;
  height: 0;
}

.slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #ccc !important; /* لون ثابت في الوضع النهاري عند التعطيل */
  transition: .4s;
  border-radius: 20px;
}

.slider:before {
  position: absolute;
  content: "";
  height: 16px;
  width: 16px;
  left: 2px;
  bottom: 2px;
  background-color: white !important; /* النقطة بيضاء دائماً عند التعطيل */
  transition: .4s;
  border-radius: 50%;
}

input:checked + .slider {
  background-color: #0066cc !important;
}

input:focus + .slider {
  box-shadow: 0 0 1px #0066cc !important;
}

input:checked + .slider:before {
  transform: translateX(26px);
  background-color: white !important; /* النقطة بيضاء عند التفعيل أيضاً */
}

.slider.round {
  border-radius: 34px;
}

.slider.round:before {
  border-radius: 50%;
}

.dark-mode .slider {
  background-color: #4b4b4b !important; /* لون ثابت في الوضع الليلي عند التعطيل */
}

.dark-mode .slider:before {
  background-color: white !important; /* النقطة بيضاء عند التعطيل في الوضع الليلي */
}

.dark-mode input:checked + .slider {
  background-color: #00c3ad !important;
}

.dark-mode input:checked + .slider:before {
  background-color: white !important; /* النقطة بيضاء عند التفعيل في الوضع الليلي */
}

/* أنماط أزرار القائمة */
.admin-menu a {
  display: flex;
  align-items: center;
  padding: 15px;
  border-radius: 12px;
  margin-bottom: 10px;
  color: #333 !important; /* لون أسود للوضع الفاتح */
  text-decoration: none;
  transition: all 0.3s ease;
  font-weight: 500;
}

.admin-menu a:hover {
  background-color: rgba(0, 102, 204, 0.1) !important; /* تأثير باللون الأزرق عند المرور في الوضع الفاتح */
  color: #0066cc !important;
  transform: translateX(-5px);
}

/* الوضع الليلي لأزرار القائمة */
.dark-mode .admin-menu a {
  color: white !important; /* لون أبيض للوضع الليلي */
}

.dark-mode .admin-menu a:hover {
  background-color: rgba(0, 195, 173, 0.1) !important; /* تأثير باللون #00c3ad عند المرور في الوضع الليلي */
  color: #00c3ad !important;
  transform: translateX(-5px);
}

/* ضمان تطبيق نفس اللون بشكل شامل على كل الأزرار في الثيم الليلي */
.dark-mode .admin-actions button,
.dark-mode .post-form button[type="submit"],
.dark-mode .admin-button {
  background-color: #00c3ad !important;
}

.dark-mode .admin-actions button:hover,
.dark-mode .post-form button[type="submit"]:hover,
.dark-mode .admin-button:hover {
  background-color: #33d1c1 !important;
}

/* === إدارة التعليقات ===== */
.comments-section {
  background-color: var(--adminPanelBg);
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.05);
}

.comments-header {
  margin-bottom: 20px;
}

.comments-header h3 {
  margin: 0 0 5px 0;
  font-size: 1.4rem;
  font-weight: 700;
  color: var(--textColor);
}

.comments-header p {
  margin: 0;
  font-size: 0.9rem;
  color: var(--secondaryTextColor);
}

.comments-filter {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  gap: 15px;
}

.comments-filter .modern-select {
  min-width: 180px;
  padding: 8px 12px;
  border-radius: 5px;
  border: 1px solid var(--borderColor);
  background-color: var(--inputBg);
  color: var(--textColor);
  font-family: 'Noto Kufi Arabic', sans-serif;
  direction: rtl;
}

.comments-filter .search-input {
  flex: 1;
  padding: 8px 12px;
  border: 1px solid var(--borderColor);
  border-radius: 5px;
  background-color: var(--inputBg);
  color: var(--textColor);
  font-family: 'Noto Kufi Arabic', sans-serif;
  direction: rtl;
}

.comments-table-container {
  overflow-x: auto;
  margin-top: 20px;
}

.comments-table {
  width: 100%;
  border-collapse: collapse;
  direction: rtl;
}

.comments-table th,
.comments-table td {
  padding: 12px 15px;
  text-align: right;
  border-bottom: 1px solid var(--borderColor);
}

.comments-table th {
  background-color: var(--tableBg);
  font-weight: bold;
  color: var(--textColor);
}

.comments-table tbody tr {
  transition: all 0.2s ease;
}

.comments-table tbody tr:hover {
  background-color: var(--rowHoverBg);
}

.post-column {
  width: 25%;
}

.author-column {
  width: 15%;
}

.date-column {
  width: 15%;
}

.status-column {
  width: 15%;
}

.actions-column {
  width: 15%;
}

.post-cell {
  display: flex;
  flex-direction: column;
}

.post-title {
  font-weight: 500;
  color: var(--textColor);
  margin-bottom: 0.25rem;
}

.post-tags {
  display: flex;
  flex-wrap: wrap;
  gap: 5px;
}

.post-tag {
  font-size: 0.75rem;
  padding: 2px 8px;
  border-radius: 20px;
  background-color: var(--tagBg);
  color: var(--tagColor);
}

.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 20px;
  font-size: 0.9rem;
  font-weight: 500;
}

.status-approved {
  background-color: rgba(0, 150, 0, 0.15);
  color: #00a000;
}

.status-rejected {
  background-color: rgba(220, 0, 0, 0.15);
  color: #d70000;
}

.status-pending {
  background-color: rgba(200, 150, 0, 0.15);
  color: #c28500;
}

.actions-cell {
  display: flex;
  gap: 8px;
  justify-content: flex-start;
}

.action-btn {
  background: none;
  border: none;
  width: 30px;
  height: 30px;
  border-radius: 5px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  color: var(--iconColor);
  transition: all 0.2s ease;
}

.action-btn:hover:not(:disabled) {
  background-color: var(--buttonHoverBg);
  color: var(--primaryColor);
}

.delete-btn:hover {
  background-color: rgba(220, 0, 0, 0.1);
  color: #d70000;
}

.empty-table-message {
  text-align: center;
  padding: 30px 0;
  color: var(--secondaryTextColor);
}

/* مشاهدة التعليق */
.comment-preview-modal {
  padding: 20px;
  max-width: 600px;
  background-color: #f5f5f5;
  border-radius: 10px;
  box-shadow: 0 5px 20px rgba(0, 0, 0, 0.2);
}

.comment-preview-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 15px;
  padding-bottom: 10px;
  border-bottom: 1px solid var(--borderColor);
}

.comment-preview-header h3 {
  margin: 0;
  font-size: 1.2rem;
  color: var(--textColor);
}

.comment-preview-close {
  background: none;
  border: none;
  font-size: 1.2rem;
  cursor: pointer;
  color: var(--textColor);
}

.comment-preview-content {
  margin-bottom: 20px;
}

.comment-preview-meta {
  display: flex;
  flex-wrap: wrap;
  gap: 15px;
  margin-bottom: 15px;
}

.comment-preview-meta div {
  display: flex;
  align-items: center;
  gap: 5px;
}

.comment-preview-meta strong {
  color: var(--textColor);
}

.comment-preview-text {
  padding: 15px;
  background-color: var(--inputBg);
  border-radius: 8px;
  margin-bottom: 20px;
  color: var(--textColor);
  white-space: pre-wrap;
}

.comment-preview-actions {
  display: flex;
  justify-content: flex-end;
  gap: 10px;
}

.comments-replies-container {
  margin-top: 20px;
  padding-top: 15px;
  border-top: 1px solid var(--borderColor);
}

.comments-replies-title {
  margin-bottom: 15px;
  font-size: 1rem;
  color: var(--textColor);
}

.comment-reply-item {
  padding: 12px;
  margin-bottom: 10px;
  background-color: var(--inputBg);
  border-radius: 8px;
  border-right: 3px solid var(--borderColor);
}

.admin-reply-item.approved {
  border-right-color: #00a000;
}

.admin-reply-item.rejected {
  border-right-color: #d70000;
}

.admin-reply-item.pending {
  border-right-color: #c28500;
}

.comment-reply-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
  font-size: 0.85rem;
  color: var(--secondaryTextColor);
}

.comment-reply-text {
  color: var(--textColor);
}

.comment-reply-actions {
  display: flex;
  justify-content: flex-end;
  margin-top: 8px;
  gap: 8px;
}

.admin-comment-item {
  border: 1px solid var(--borderColor);
  border-right: 4px solid var(--borderColor);
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  background-color: var(--adminPanelBg);
  transition: transform 0.2s ease;
}

.admin-comment-item:hover {
  transform: translateY(-2px);
}

.admin-comment-item.approved {
  border-right-color: #00a000;
}

.admin-comment-item.rejected {
  border-right-color: #d70000;
}

.admin-comment-item.pending {
  border-right-color: #c28500;
}

.admin-comments {
  max-height: 600px;
  overflow-y: auto;
  padding-right: 5px;
}

/* Añadir estilos para modo oscuro específicos para comentarios */
body.dark-mode .status-approved {
  background-color: rgba(0, 200, 0, 0.2);
  color: #50e150;
}

body.dark-mode .status-rejected {
  background-color: rgba(255, 0, 0, 0.2);
  color: #ff6b6b;
}

body.dark-mode .status-pending {
  background-color: rgba(255, 200, 0, 0.2);
  color: #ffd966;
}

body.dark-mode .comment-preview-text {
  background-color: rgba(0, 0, 0, 0.2);
}

body.dark-mode .post-tag {
  background-color: rgba(0, 0, 0, 0.2);
}

/* ===== Estilos adicionales para botones de acción ===== */
.refresh-button {
  background-color: var(--primaryColor);
  color: white;
  border: none;
  border-radius: 5px;
  padding: 8px 12px;
  font-family: 'Noto Kufi Arabic', sans-serif;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 5px;
  transition: all 0.2s ease;
}

.refresh-button:hover {
  background-color: var(--primaryColorHover);
  transform: translateY(-2px);
}

.action-button {
  padding: 8px 15px;
  border-radius: 5px;
  border: none;
  font-family: 'Noto Kufi Arabic', sans-serif;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  transition: all 0.2s ease;
  font-size: 0.9rem;
}

.mini-button {
  padding: 5px 10px;
  font-size: 0.8rem;
}

.approve-button {
  background-color: rgba(0, 150, 0, 0.1);
  color: #00a000;
}

.approve-button:hover {
  background-color: rgba(0, 150, 0, 0.2);
}

.reject-button {
  background-color: rgba(220, 0, 0, 0.1);
  color: #d70000;
}

.reject-button:hover {
  background-color: rgba(220, 0, 0, 0.2);
}

.delete-button {
  background-color: rgba(220, 0, 0, 0.1);
  color: #d70000;
}

.delete-button:hover {
  background-color: rgba(220, 0, 0, 0.2);
}

.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 1000;
  overflow-y: auto;
  padding: 20px;
}

/* Estilos para modo oscuro */
body.dark-mode .approve-button {
  background-color: rgba(0, 200, 0, 0.15);
  color: #50e150;
}

body.dark-mode .approve-button:hover {
  background-color: rgba(0, 200, 0, 0.25);
}

body.dark-mode .reject-button {
  background-color: rgba(255, 0, 0, 0.15);
  color: #ff6b6b;
}

body.dark-mode .reject-button:hover {
  background-color: rgba(255, 0, 0, 0.25);
}

body.dark-mode .delete-button {
  background-color: rgba(255, 0, 0, 0.15);
  color: #ff6b6b;
}

body.dark-mode .delete-button:hover {
  background-color: rgba(255, 0, 0, 0.25);
}

body.dark-mode .refresh-button {
  background-color: var(--primaryColor);
}

body.dark-mode .refresh-button:hover {
  background-color: var(--primaryColorHover);
}

body.dark-mode .modal-overlay {
  background-color: rgba(0, 0, 0, 0.7);
}

/* ===== إدارة الأقسام ===== */
.categories-section {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  padding: 25px;
  margin-bottom: 30px;
}

.dark-mode .categories-section {
  background-color: #2d2d2d;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

.posts-section {
  background-color: #fff;
  border-radius: 15px;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.05);
  padding: 25px;
}

.dark-mode .posts-section {
  background-color: #2d2d2d;
  box-shadow: 0 2px 15px rgba(0, 0, 0, 0.1);
}

/* تحسين مظهر الإحصائيات */
.stat-info {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.stat-number {
  font-size: 1.5rem;
  font-weight: bold;
  color: var(--primary-color);
}

.stat-title {
  font-size: 0.9rem;
  color: var(--secondary-text-color);
}

/* تنسيق صفحة الأقسام والمنشورات */
.categories-and-posts {
  display: flex;
  flex-direction: column;
  gap: 2rem;
  padding: 1.5rem;
  background: var(--bg-color);
  border-radius: 12px;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.1);
}

.section-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 1rem;
  border-bottom: 2px solid var(--border-color);
}

.section-title {
  font-size: 1.8rem;
  color: var(--primary-color);
  margin: 0;
}

.section-actions {
  display: flex;
  gap: 1rem;
}

/* تنسيق قسم الأقسام */
.categories-section {
  background: var(--card-bg);
  border-radius: 8px;
  padding: 1.5rem;
  margin-bottom: 2rem;
}

.categories-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 1rem;
  margin-top: 1rem;
}

.category-card {
  background: var(--bg-color);
  border: 1px solid var(--border-color);
  border-radius: 8px;
  padding: 1rem;
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  transition: all 0.3s ease;
}

.category-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 12px rgba(0, 0, 0, 0.1);
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

.category-name {
  font-size: 1.1rem;
  font-weight: 600;
  color: var(--text-color);
  margin: 0;
}

.category-actions {
  display: flex;
  gap: 0.5rem;
}

.category-stats {
  display: flex;
  align-items: center;
  gap: 1rem;
  color: var(--secondary-text-color);
  font-size: 0.9rem;
}

/* تنسيق قسم المنشورات */
.posts-section {
  background: var(--card-bg);
  border-radius: 8px;
  padding: 1.5rem;
}

.posts-filters {
  display: flex;
  gap: 1rem;
  margin-bottom: 1.5rem;
  flex-wrap: wrap;
}

.filter-group {
  display: flex;
  align-items: center;
  gap: 0.5rem;
}

.posts-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
  margin-top: 1rem;
}

.posts-table th,
.posts-table td {
  padding: 12px 15px;
  text-align: right;
  border-bottom: 1px solid var(--border-color);
}

.posts-table th {
  background-color: var(--header-bg);
  font-weight: bold;
  color: var(--header-text);
}

.posts-table tr:hover {
  background-color: var(--hover-color);
}

.post-thumbnail {
  width: 40px;
  height: 40px;
  overflow: hidden;
  border-radius: 4px;
  margin-left: 10px;
}

.post-thumbnail img {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.post-actions {
  display: flex;
  gap: 5px;
}

.view-button,
.edit-button,
.delete-button,
.save-button,
.cancel-button {
  padding: 6px;
  border: none;
  background-color: var(--bg-color-secondary);
  color: var(--text-color);
  border-radius: 4px;
  cursor: pointer;
  transition: all 0.2s ease;
}

.view-button:hover {
  background-color: #67b7e1;
  color: white;
}

.edit-button:hover {
  background-color: #68b468;
  color: white;
}

.delete-button:hover {
  background-color: #dc3545;
  color: white;
}

.save-button:hover {
  background-color: #28a745;
  color: white;
}

.cancel-button:hover {
  background-color: #6c757d;
  color: white;
}

/* تنسيق حالة المنشور */
.status-badge {
  display: inline-block;
  padding: 4px 8px;
  border-radius: 4px;
  font-size: 12px;
  font-weight: 500;
}

.status-badge.published {
  background-color: #28a745;
  color: white;
}

.status-badge.draft {
  background-color: #6c757d;
  color: white;
}

.status-badge.scheduled {
  background-color: #ffc107;
  color: black;
}

/* تنسيق اسم الموقع "El Gedid" بتأثير موجة ضوء باللون الأزرق والتركواز */
.site-name-container {
  text-align: center;
  padding: 15px 0;
  margin-bottom: 10px;
  border-bottom: none;
  position: relative;
  overflow: hidden;
}

.site-name {
  font-size: 28px;
  font-weight: 700;
  position: relative;
  margin: 0;
  padding: 10px 0;
  letter-spacing: 1px;
  display: inline-block;
}

/* ألوان متوافقة مع الثيم الأبيض */
.site-name .el {
  color: #1976d2; /* أزرق */
  text-shadow: 0 0 5px rgba(25, 118, 210, 0.3);
}

.site-name .gedid {
  color: #00bcd4; /* تركواز */
  text-shadow: 0 0 5px rgba(0, 188, 212, 0.3);
}

/* ألوان متوافقة مع الثيم الأسود */
.dark-mode .site-name .el {
  color: #64b5f6; /* أزرق فاتح */
  text-shadow: 0 0 8px rgba(100, 181, 246, 0.5);
}

.dark-mode .site-name .gedid {
  color: #4dd0e1; /* تركواز فاتح */
  text-shadow: 0 0 8px rgba(77, 208, 225, 0.5);
}

/* تأثير موجة الضوء */
.light-wave {
  position: absolute;
  bottom: 0;
  left: -100%;
  width: 100%;
  height: 2px;
  background: linear-gradient(to right, #1976d2, #00bcd4);
  animation: light-wave-anim 2s ease-in-out infinite;
  box-shadow: 0 0 10px rgba(0, 188, 212, 0.7);
  z-index: 1;
}

.dark-mode .light-wave {
  background: linear-gradient(to right, #64b5f6, #4dd0e1);
  box-shadow: 0 0 15px rgba(77, 208, 225, 0.8);
}

@keyframes light-wave-anim {
  0% {
    left: -100%;
    width: 100%;
  }
  50% {
    left: 100%;
    width: 100%;
  }
  100% {
    left: 100%;
    width: 0%;
  }
}

/* تعديلات للقائمة المطوية */
.collapsed .site-name-container {
  padding: 10px 0;
}

.collapsed .site-name {
  font-size: 18px;
}

.collapsed .site-name .el {
  display: block;
}

.collapsed .site-name .gedid {
  display: block;
  line-height: 1;
}

/* أنيميشن للقائمة المطوية */
@keyframes jumpElCollapsed {
  0% {
    transform: translateY(0) scale(1);
    text-shadow: 0 0 0 rgba(33, 150, 243, 0);
  }
  10%, 15% {
    transform: translateY(-3px) scale(1.1);
    text-shadow: 0 0 10px rgba(33, 150, 243, 0.8);
  }
  20% {
    transform: translateY(0) scale(1);
    text-shadow: 0 0 0 rgba(33, 150, 243, 0);
  }
}

@keyframes jumpGedidCollapsed {
  0%, 20%, 100% {
    transform: translateY(0) scale(1);
    text-shadow: 0 0 0 rgba(0, 188, 212, 0);
  }
  30%, 35% {
    transform: translateY(-3px) scale(1.1);
    text-shadow: 0 0 10px rgba(0, 188, 212, 0.8);
  }
  40% {
    transform: translateY(0) scale(1);
    text-shadow: 0 0 0 rgba(0, 188, 212, 0);
  }
}

/* تحسين تأثير التوهج في الوضع الداكن للقائمة المطوية */
.dark-mode .collapsed .logo-el {
  animation: jumpElCollapsedDark 3s ease-in-out infinite;
}

.dark-mode .collapsed .logo-gedid {
  animation: jumpGedidCollapsedDark 3s ease-in-out infinite;
}

@keyframes jumpElCollapsedDark {
  0% {
    transform: translateY(0) scale(1);
    text-shadow: 0 0 0 rgba(100, 181, 246, 0);
  }
  10%, 15% {
    transform: translateY(-3px) scale(1.1);
    text-shadow: 0 0 15px rgba(100, 181, 246, 1);
  }
  20% {
    transform: translateY(0) scale(1);
    text-shadow: 0 0 0 rgba(100, 181, 246, 0);
  }
}

@keyframes jumpGedidCollapsedDark {
  0%, 20%, 100% {
    transform: translateY(0) scale(1);
    text-shadow: 0 0 0 rgba(77, 208, 225, 0);
  }
  30%, 35% {
    transform: translateY(-3px) scale(1.1);
    text-shadow: 0 0 15px rgba(77, 208, 225, 1);
  }
  40% {
    transform: translateY(0) scale(1);
    text-shadow: 0 0 0 rgba(77, 208, 225, 0);
  }
}

/* إلغاء الأنماط القديمة */
.site-name-container,
.site-name,
.el,
.gedid,
.light-wave,
.logo-el::after,
.logo-gedid::after {
  display: none;
}

/* تبويبات الإدارة */

/* أنماط القسم الرئيسي والتبويبات */
.admin-panel-section {
  background: #ffffff;
  border-radius: 20px;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  padding: 25px;
  margin-bottom: 30px;
}

.dark-mode .admin-panel-section {
  background: #2a2a2a;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.2);
}

.admin-panel-section h2 {
  color: var(--text-primary);
  font-size: 1.8rem;
  margin-bottom: 30px;
  font-weight: 700;
  text-align: center;
  padding-bottom: 15px;
}

.dark-mode .admin-panel-section h2 {
  color: var(--text-primary);
}

.admin-panel-section h2::before {
  display: none;
}

.tabs-container {
  margin-top: 20px;
}

.tabs-header {
  display: flex;
  justify-content: center;
  gap: 15px;
  margin-bottom: 25px;
  border-bottom: 2px solid #eee;
  padding-bottom: 10px;
}

.dark-mode .tabs-header {
  border-bottom-color: #3a3a3a;
}

.tabs-header .tab {
  padding: 10px 20px;
  border: none;
  border-radius: 10px 10px 0 0;
  background: none;
  cursor: pointer;
  font-family: 'Cairo', sans-serif;
  font-size: 1rem;
  font-weight: 600;
  color: #333;
  transition: all 0.3s ease;
}

.tabs-header .tab:hover {
  background-color: #f5f5f5;
  color: #0066cc;
}

.tabs-header .tab.active {
  background-color: #0066cc;
  color: white;
  border-radius: 10px 10px 0 0;
}

.dark-mode .tabs-header .tab {
  color: #e0e0e0;
}

.dark-mode .tabs-header .tab:hover {
  background-color: #333;
  color: #00c3ad;
}

.dark-mode .tabs-header .tab.active {
  background-color: #00c3ad;
  color: #222;
  border-radius: 10px 10px 0 0;
}

/* تصميم جدول المنشورات */
.posts-table-container {
  background: #fff;
  border-radius: 15px;
  overflow: hidden;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
  margin-top: 20px;
}

.dark-mode .posts-table-container {
  background: #2a2a2a;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.2);
}

.posts-table {
  width: 100%;
  border-collapse: separate;
  border-spacing: 0;
}

.posts-table th {
  background: #f8f9fa;
  color: #333;
  font-weight: 600;
  padding: 15px;
  text-align: right;
  border-bottom: 2px solid #eee;
}

.dark-mode .posts-table th {
  background: #333;
  color: #f0f0f0;
  border-bottom-color: #444;
}

.posts-table td {
  padding: 15px;
  border-bottom: 1px solid #eee;
  color: #666;
  transition: all 0.3s ease;
}

.dark-mode .posts-table td {
  border-bottom-color: #444;
  color: #ccc;
}

.posts-table tr:hover td {
  background-color: #f5f5f5;
}

.dark-mode .posts-table tr:hover td {
  background-color: #333;
}

/* تصميم المنشور المثبت */
.pinned-post {
  background-color: rgba(0, 102, 204, 0.05);
}

.dark-mode .pinned-post {
  background-color: rgba(0, 195, 173, 0.05);
}

.pinned-post td:first-child::before {
  content: '\f08d';
  font-family: 'Font Awesome 5 Free';
  font-weight: 900;
  color: #0066cc;
  margin-left: 8px;
  font-size: 0.9rem;
}

.dark-mode .pinned-post td:first-child::before {
  color: #00c3ad;
}

/* تصميم زر إضافة منشور جديد */
.add-button {
  background: linear-gradient(45deg, #0066cc, #0088ff);
  color: white;
  border: none;
  padding: 12px 25px;
  border-radius: 50px;
  font-weight: 600;
  display: flex;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  box-shadow: 0 4px 15px rgba(0, 102, 204, 0.2);
  margin-bottom: 20px;
}

.dark-mode .add-button {
  background: linear-gradient(45deg, #00a896, #00c3ad);
  box-shadow: 0 4px 15px rgba(0, 195, 173, 0.2);
}

.add-button:hover {
  transform: translateY(-2px);
  box-shadow: 0 6px 20px rgba(0, 102, 204, 0.3);
}

.dark-mode .add-button:hover {
  box-shadow: 0 6px 20px rgba(0, 195, 173, 0.3);
}

.add-button svg {
  width: 16px;
  height: 16px;
}

/* تصميم أزرار الإجراءات */
.post-actions {
  display: flex;
  gap: 8px;
}

.post-actions button {
  width: 35px;
  height: 35px;
  padding: 0;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  transition: all 0.3s ease;
  color: white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.post-actions button svg {
  width: 14px;
  height: 14px;
}

.view-button {
  background-color: #28a745;
}

.edit-button {
  background-color: #0066cc;
}

.delete-button {
  background-color: #dc3545;
}

.post-actions button:hover {
  transform: translateY(-2px);
  filter: brightness(1.1);
}

/* تصميم رسالة عدم وجود منشورات */
.no-posts-message {
  text-align: center;
  padding: 40px;
  color: #666;
  font-size: 1.1rem;
  background: #f8f9fa;
  border-radius: 10px;
  margin: 20px 0;
}

.dark-mode .no-posts-message {
  color: #999;
  background: #333;
}

/* === تنسيق شريط البحث === */
.search-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 1.5rem auto;
  width: 100%;
  max-width: 500px;
  direction: rtl;
}

.search-container {
  display: flex;
  width: 100%;
  max-width: 500px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: transparent !important;
  background: transparent !important;
  border: none !important;
}

.search-input {
  flex: 1;
  padding: 13px 24px;
  border: none !important;
  outline: none !important;
  font-size: 1.1rem;
  font-weight: 500;
  background-color: transparent !important;
  background: transparent !important;
  color: var(--text);
  direction: rtl;
  border-radius: 5px;
  transition: all 0.3s ease;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

.search-input::placeholder {
  color: rgba(108, 117, 125, 0.8);
  transition: all 0.3s ease;
}

.search-input:-webkit-autofill,
.search-input:-webkit-autofill:hover,
.search-input:-webkit-autofill:focus {
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent !important;
  box-shadow: 0 0 0px 1000px transparent inset !important;
}

.search-input:focus-visible,
.light-mode .search-input:focus,
.dark-mode .search-input:focus,
.light-mode .search-input:hover,
.dark-mode .search-input:hover {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}

.search-container.active {
  border: none !important;
  outline: none !important;
}

.light-mode .search-container {
  box-shadow: 0 0 12px 3px rgba(30, 144, 255, 0.4);
  animation: pulsate-light 3s ease-in-out infinite alternate;
}

.light-mode .search-container.active {
  box-shadow: 0 0 30px 8px rgba(30, 144, 255, 0.9);
  animation: none;
}

.dark-mode .search-container {
  box-shadow: 0 0 12px 3px rgba(0, 195, 173, 0.4);
  animation: pulsate-dark 3s ease-in-out infinite alternate;
}

.dark-mode .search-container.active {
  box-shadow: 0 0 30px 8px rgba(0, 195, 173, 0.9);
  animation: none;
}

.search-button {
  width: 50px;
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
  background: transparent !important;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  color: var(--text);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  font-size: 1.2rem;
}

@keyframes pulsate-light {
  0% {
    box-shadow: 0 0 12px 3px rgba(30, 144, 255, 0.4);
  }
  50% {
    box-shadow: 0 0 20px 5px rgba(30, 144, 255, 0.6);
  }
  100% {
    box-shadow: 0 0 30px 8px rgba(30, 144, 255, 0.8);
  }
}

@keyframes pulsate-dark {
  0% {
    box-shadow: 0 0 12px 3px rgba(0, 195, 173, 0.4);
  }
  50% {
    box-shadow: 0 0 20px 5px rgba(0, 195, 173, 0.6);
  }
  100% {
    box-shadow: 0 0 30px 8px rgba(0, 195, 173, 0.8);
  }
}

.search-button:active {
  transform: scale(0.95);
}

.light-mode .search-input {
  border: 2px solid rgba(30, 144, 255, 0.4);
  -webkit-text-fill-color: var(--text) !important;
}

.dark-mode .search-input {
  border: 2px solid rgba(0, 195, 173, 0.4);
  color: white;
  -webkit-text-fill-color: white !important;
}

.dark-mode .search-input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.light-mode .search-input:focus {
  box-shadow: 0 0 15px rgba(30, 144, 255, 0.7);
  border-color: rgba(30, 144, 255, 0.8);
}

.dark-mode .search-input:focus {
  box-shadow: 0 0 15px rgba(0, 195, 173, 0.7);
  border-color: rgba(0, 195, 173, 0.8);
}

.light-mode .search-button {
  color: #1E90FF;
}

.dark-mode .search-button {
  color: #00C3AD;
}

/* تعديل على العناوين لإزالة الخط الأزرق وجعلها وسطية */
.admin-content h2, .admin-content h3 {
  text-align: center;
  margin-bottom: 1.5rem;
  position: relative;
  padding-bottom: 0.5rem;
  font-weight: 600;
}

.admin-content h2::after, .admin-content h3::after {
  display: none;
}

/* تعديل على حاويات المحتوى لتكون متوسطة */
.admin-panel-section {
  text-align: center;
}

/* تنسيق حاوية إضافة الفئات */
.add-category-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  margin: 1.5rem auto;
  max-width: 500px;
}

.add-category-input-group {
  display: flex;
  width: 100%;
  gap: 10px;
  margin-bottom: 1rem;
  justify-content: center;
}

.add-category-input {
  flex: 1;
  max-width: 350px;
}

/* === تنسيق شريط البحث الجديد === */
.search-bar {
  display: flex;
  width: 100%;
  max-width: 500px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin: 1rem auto;
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.dark-mode .search-bar {
  box-shadow: 0 0 12px 3px rgba(0, 195, 173, 0.25);
}

.light-mode .search-bar {
  box-shadow: 0 0 12px 3px rgba(30, 144, 255, 0.25);
}

.search-bar .search-input {
  flex: 1;
  padding: 12px 20px;
  border: none;
  outline: none;
  font-size: 1rem;
  background-color: var(--background-light);
  color: var(--text);
  direction: rtl;
}

.dark-mode .search-bar .search-input {
  background-color: #2d2d2d;
  color: #fff;
  border: 1px solid rgba(0, 195, 173, 0.25);
}

.light-mode .search-bar .search-input {
  background-color: #fff;
  color: #333;
  border: 1px solid rgba(30, 144, 255, 0.25);
}

.search-bar .search-input::placeholder {
  color: #888;
}

.dark-mode .search-bar .search-input::placeholder {
  color: rgba(255, 255, 255, 0.6);
}

.search-bar .search-button {
  width: 50px;
  border: none;
  background-color: var(--primary);
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: background-color 0.3s;
}

.dark-mode .search-bar .search-button {
  background-color: rgba(0, 195, 173, 0.8);
}

.light-mode .search-bar .search-button {
  background-color: rgba(30, 144, 255, 0.8);
}

.search-bar .search-button:hover {
  opacity: 0.9;
}

.search-bar .clear-search-button {
  width: 40px;
  border: none;
  background-color: transparent;
  color: #888;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  position: absolute;
  left: 50px;
  top: 0;
  bottom: 0;
}

.dark-mode .search-bar .clear-search-button {
  color: rgba(255, 255, 255, 0.6);
}

.search-bar .clear-search-button:hover {
  color: var(--danger);
  transform: scale(1.1);
}

/* تحسينات للبحث والتوافق مع الشاشات الصغيرة */
@media screen and (max-width: 768px) {
  .search-bar {
    max-width: 100%;
    margin: 0.5rem 0;
  }
  
  .search-bar .search-input {
    padding: 10px 16px;
    font-size: 0.9rem;
  }
  
  .search-bar .search-button,
  .search-bar .clear-search-button {
    padding: 0 10px;
  }
  
  .posts-control-bar,
  .comments-filters {
    flex-direction: column;
    align-items: stretch;
  }
  
  .posts-control-bar .posts-actions,
  .posts-control-bar .search-bar,
  .comments-filters .search-bar,
  .comments-filters .filter-options {
    width: 100%;
    margin: 0.5rem 0;
  }
}

.search-bar:focus-within {
  box-shadow: 0 0 15px rgba(0, 195, 173, 0.6);
  transform: translateY(-1px);
}

.light-mode .search-bar:focus-within {
  box-shadow: 0 0 15px rgba(30, 144, 255, 0.6);
}

.dark-mode .search-bar:focus-within {
  box-shadow: 0 0 15px rgba(0, 195, 173, 0.7);
}

/* تعديل موضع شريط البحث ليكون في المنتصف تماماً */
.posts-control-bar, .comments-filters {
  justify-content: center;
  gap: 1rem;
}

.search-bar {
  margin: 1rem auto;
}

.posts-actions, .filter-options {
  display: flex;
  gap: 1rem;
}

/* تأثير التوهج لشريط البحث */
@keyframes glowPulse {
  0% { box-shadow: 0 0 15px rgba(0, 195, 173, 0.25); }
  50% { box-shadow: 0 0 25px rgba(0, 195, 173, 1); }
  100% { box-shadow: 0 0 15px rgba(0, 195, 173, 0.25); }
}

@keyframes glowPulseLight {
  0% { box-shadow: 0 0 15px rgba(30, 144, 255, 0.25); }
  50% { box-shadow: 0 0 25px rgba(30, 144, 255, 1); }
  100% { box-shadow: 0 0 15px rgba(30, 144, 255, 0.25); }
}

.search-bar:focus-within {
  animation: glowPulse 2s infinite;
  transform: translateY(-1px);
}

.light-mode .search-bar:focus-within {
  animation: glowPulseLight 2s infinite;
}

.dark-mode .search-bar:focus-within {
  animation: glowPulse 2s infinite;
}

/* تأثيرات التوهج الجديدة بزيادة قوة الإضاءة */
@keyframes glowPulseDark {
  0% { box-shadow: 0 0 15px rgba(0, 195, 173, 0.25); }
  50% { box-shadow: 0 0 30px rgba(0, 195, 173, 1); }
  100% { box-shadow: 0 0 15px rgba(0, 195, 173, 0.25); }
}

@keyframes glowPulseLight {
  0% { box-shadow: 0 0 15px rgba(30, 144, 255, 0.25); }
  50% { box-shadow: 0 0 30px rgba(30, 144, 255, 1); }
  100% { box-shadow: 0 0 15px rgba(30, 144, 255, 0.25); }
}

.search-bar:focus-within {
  animation: glowPulse 2s infinite;
  transform: translateY(-1px);
}

.light-mode .search-bar:focus-within {
  animation: glowPulseLight 2s infinite;
}

.dark-mode .search-bar:focus-within {
  animation: glowPulse 2s infinite;
}

/* تطبيق تأثير التوهج النبضي على جميع أشرطة البحث */
.search-bar {
  display: flex;
  width: 100%;
  max-width: 500px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  margin: 1rem auto;
  transition: all 0.3s ease;
}

.dark-mode .search-bar {
  animation: glowPulseDark 3s infinite ease-in-out;
}

.light-mode .search-bar {
  animation: glowPulseLight 3s infinite ease-in-out;
}

/* زيادة سرعة النبض عند التركيز */
.dark-mode .search-bar:focus-within {
  animation: glowPulseDark 1.5s infinite ease-in-out;
  transform: translateY(-1px);
}

.light-mode .search-bar:focus-within {
  animation: glowPulseLight 1.5s infinite ease-in-out;
  transform: translateY(-1px);
}

/* تنسيقات لمعاينة المنشور في صفحة الإدارة - مماثلة لصفحة المنشور الكاملة */
.post-preview-container {
  width: 100%;
  max-width: 1200px;
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  overflow: hidden;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.dark-mode .post-preview-container {
  background-color: #333333;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

.post-preview {
  padding: 40px;
  width: 100%;
}

.preview-post-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  line-height: 1.3;
  color: #333;
  font-weight: 700;
  transition: color 0.3s ease;
}

.dark-mode .preview-post-title {
  color: #f0f0f0;
}

.light-mode .preview-post-title {
  color: #1E90FF;
}

.preview-post-meta {
  display: flex;
  gap: 20px;
  margin-bottom: 25px;
  color: #666;
  font-size: 0.95rem;
  text-align: right;
}

.preview-post-author, .preview-post-date, .preview-post-category {
  display: flex;
  align-items: center;
  gap: 8px;
}

.preview-post-date svg, .preview-post-category svg, .preview-post-author svg {
  color: #1E90FF;
}

.dark-mode .preview-post-date svg, 
.dark-mode .preview-post-category svg, 
.dark-mode .preview-post-author svg {
  color: #00c3ad;
}

.preview-post-author {
  margin-bottom: 20px;
  display: flex;
  align-items: center;
  gap: 8px;
  font-style: italic;
  color: #777;
}

.preview-post-featured-image {
  width: 100%;
  margin-bottom: 30px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  max-height: 500px;
  background-color: #f0f0f0;
}

.preview-post-featured-image img {
  width: 100%;
  height: 100%;
  max-height: 500px;
  object-fit: contain;
  display: block;
  transition: transform 0.3s ease;
}

.preview-post-featured-image:hover img {
  transform: scale(1.02);
}

.preview-post-content {
  font-size: 1.1rem;
  line-height: 1.8;
  color: #444;
  margin-bottom: 30px;
  text-align: right;
}

.dark-mode .preview-post-content {
  color: #ddd;
}

/* تنسيق الصور داخل محتوى المنشور في المعاينة */
.preview-post-content img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 20px 0;
  display: block;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
  object-fit: contain;
}

.preview-post-tags {
  margin-top: 30px;
}

.preview-post-tags h3 {
  margin-bottom: 15px;
  font-size: 1.2rem;
  color: #333;
}

.dark-mode .preview-post-tags h3 {
  color: #f0f0f0;
}

.preview-actions {
  display: flex;
  gap: 15px;
  justify-content: flex-end;
}

.preview-actions button {
  padding: 10px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
}

.edit-btn {
  background-color: #f0f0f0;
  color: #333;
  border: 1px solid #ddd;
}

.edit-btn:hover {
  background-color: #e0e0e0;
}

.publish-btn {
  background-color: #1E90FF;
  color: white;
  border: none;
}

.publish-btn:hover {
  background-color: #0066cc;
}

.dark-mode .edit-btn {
  background-color: #444;
  color: #f0f0f0;
}

.dark-mode .edit-btn:hover {
  background-color: #555;
}

.dark-mode .publish-btn {
  background-color: #00c3ad;
}

.dark-mode .publish-btn:hover {
  background-color: #00a896;
}

/* أنماط قسم تحليلات الزيارات */
.analytics-section {
  margin-top: 2rem;
  border-radius: 15px;
  position: relative;
  overflow: hidden;
  padding: 0;
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.1);
}

.dark-mode .analytics-section {
  box-shadow: 0 10px 30px rgba(0, 0, 0, 0.3);
}

.analytics-header {
  background: linear-gradient(135deg, #6b73ff 0%, #000dff 100%);
  color: white;
  padding: 1.8rem 2rem;
  position: relative;
  overflow: hidden;
}

.dark-mode .analytics-header {
  background: linear-gradient(135deg, #6a11cb 0%, #2575fc 100%);
}

.analytics-title {
  font-size: 1.5rem;
  font-weight: 800;
  margin: 0;
  display: flex;
  align-items: center;
  position: relative;
  z-index: 1;
}

.analytics-title svg {
  font-size: 1.8rem;
  margin-left: 12px;
}

.analytics-subtitle {
  font-size: 1rem;
  font-weight: 400;
  margin-top: 0.5rem;
  opacity: 0.8;
  position: relative;
  z-index: 1;
}

.reset-visits-button {
  background: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(5px);
  border: 1px solid rgba(255, 255, 255, 0.2);
  color: white;
  padding: 8px 16px;
  border-radius: 12px;
  cursor: pointer;
  font-family: 'Cairo', sans-serif;
  font-size: 0.9rem;
  font-weight: 600;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  position: absolute;
  top: 1.8rem;
  left: 2rem;
  z-index: 2;
}

.reset-visits-button svg {
  margin-left: 8px;
}

.reset-visits-button:hover {
  background: rgba(255, 255, 255, 0.25);
  transform: translateY(-2px);
}

.analytics-content {
  background-color: #fff;
  padding: 2rem;
}

.dark-mode .analytics-content {
  background-color: #222;
}

.analytics-tabs {
  display: flex;
  justify-content: space-between;
  background: none;
  margin-bottom: 2rem;
}

.analytics-tab {
  flex: 1;
  padding: 1rem;
  border: none;
  border-bottom: 3px solid #eee;
  background: none;
  cursor: pointer;
  font-family: 'Cairo', sans-serif;
  font-size: 0.95rem;
  font-weight: 600;
  color: #888;
  transition: all 0.3s ease;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;
}

.dark-mode .analytics-tab {
  color: #777;
  border-bottom: 3px solid #333;
}

.analytics-tab svg {
  font-size: 1.5rem;
  margin-bottom: 8px;
}

.analytics-tab::after {
  content: '';
  position: absolute;
  bottom: -3px;
  left: 0;
  width: 0;
  height: 3px;
  background: linear-gradient(to right, #6b73ff, #000dff);
  transition: width 0.3s ease;
}

.dark-mode .analytics-tab::after {
  background: linear-gradient(to right, #6a11cb, #2575fc);
}

.analytics-tab:hover {
  color: #444;
}

.dark-mode .analytics-tab:hover {
  color: #ccc;
}

.analytics-tab:hover::after {
  width: 30%;
}

.analytics-tab.active {
  color: #6b73ff;
  border-bottom: 3px solid transparent;
}

.dark-mode .analytics-tab.active {
  color: #2575fc;
  border-bottom: 3px solid transparent;
}

.analytics-tab.active::after {
  width: 100%;
}

.analytics-chart-container {
  width: 100%;
  height: 100%;
  position: relative;
  background: none;
}

.summary-cards {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
  margin-bottom: 2rem;
}

.summary-card {
  background-color: #f8f9fa;
  border-radius: 15px;
  padding: 1.5rem;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
  position: relative;
  overflow: hidden;
}

.dark-mode .summary-card {
  background-color: #2d2d2d;
  box-shadow: 0 4px 15px rgba(0, 0, 0, 0.15);
}

.summary-card::before {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background: linear-gradient(135deg, rgba(107, 115, 255, 0.1) 0%, rgba(0, 13, 255, 0.1) 100%);
  opacity: 0;
  transition: opacity 0.3s ease;
}

.dark-mode .summary-card::before {
  background: linear-gradient(135deg, rgba(106, 17, 203, 0.1) 0%, rgba(37, 117, 252, 0.1) 100%);
}

.summary-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.dark-mode .summary-card:hover {
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.2);
}

.summary-card:hover::before {
  opacity: 1;
}

.summary-icon {
  position: absolute;
  top: 1.5rem;
  left: 1.5rem;
  font-size: 1.5rem;
  color: #6b73ff;
  opacity: 0.2;
}

.dark-mode .summary-icon {
  color: #2575fc;
}

.summary-label {
  font-size: 1rem;
  color: #777;
  margin-bottom: 0.75rem;
  font-weight: 600;
}

.dark-mode .summary-label {
  color: #aaa;
}

.summary-value {
  font-size: 2rem;
  font-weight: 800;
  color: #333;
  margin: 0;
  line-height: 1;
}

.dark-mode .summary-value {
  color: #eee;
}

.summary-date {
  font-size: 0.85rem;
  color: #999;
  margin-top: 0.5rem;
}

.dark-mode .summary-date {
  color: #777;
}

.chart-container {
  position: relative;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.analytics-footer {
  display: flex;
  justify-content: center;
  padding: 1.5rem 0 0.5rem;
  color: #999;
  font-size: 0.9rem;
  border-top: 1px dashed #eee;
  margin-top: 2rem;
}

.dark-mode .analytics-footer {
  color: #777;
  border-top: 1px dashed #333;
}

.analytics-footer svg {
  margin-left: 0.5rem;
}

@media (max-width: 992px) {
  .summary-cards {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 576px) {
  .summary-cards {
    grid-template-columns: 1fr;
  }
  
  .analytics-tabs {
    flex-wrap: wrap;
  }
  
  .analytics-tab {
    flex: 0 0 50%;
    padding: 0.75rem;
  }
}
.preview-post-image {
  width: 100%;
  margin-bottom: 30px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  max-height: 500px;
  background-color: #f0f0f0;
}

.preview-post-image img {
  width: 100%;
  height: 100%;
  max-height: 500px;
  object-fit: contain;
  display: block;
  transition: transform 0.3s ease;
}

.preview-post-image img:hover {
  transform: scale(1.02);
}

.preview-actions .back-btn {
  background-color: #f0f0f0;
  color: #333;
  border: none;
  font-size: 14px;
  cursor: pointer;
  transition: all 0.3s ease;
  padding: 10px 16px;
  display: flex;
  align-items: center;
  gap: 8px;
  border-radius: 8px;
  font-weight: 600;
}

.preview-actions .back-btn:hover {
  background-color: #e0e0e0;
  transform: translateY(-2px);
}

.dark-mode .preview-actions .back-btn {
  background-color: #444;
  color: #f0f0f0;
}

.dark-mode .preview-actions .back-btn:hover {
  background-color: #555;
}

/* تنسيق صفحة تسجيل الدخول المركزية */
.centered-login-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.login-container {
  max-width: 400px;
  width: 100%;
}
