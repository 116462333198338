/* تنسيق الشعار الجديد */
.site-logo {
  padding: 5px 15px 5px;
  text-align: center;
  border: none !important;
  margin-bottom: 0;
  margin-top: 15px;
  position: relative;
  background: transparent !important;
  box-shadow: none !important;
}

.logo-wrapper {
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: 40px;
  margin-bottom: 25px;
  padding-bottom: 0;
  border: none !important;
}

.no-border,
.no-border::before,
.no-border::after {
  border: none !important;
  border-bottom: none !important;
  box-shadow: none !important;
  background-image: none !important;
  outline: none !important;
  text-decoration: none !important;
}

.site-logo::after,
.site-logo::before,
.site-logo hr,
.site-logo * hr {
  display: none !important; /* إزالة أي خط محتمل */
  border: none !important;
  height: 0 !important;
  width: 0 !important;
  opacity: 0 !important;
}

.logo-text {
  display: flex;
  flex-direction: row; 
  align-items: center;
  justify-content: center;
  font-weight: bold;
  text-decoration: none;
  border: none;
}

.logo-gedid {
  color: #0097e6;
  font-size: 32px; 
  margin-right: 5px;
  animation: jumpEffect 2s infinite;
  animation-delay: 1s; /* تأخير حركة Gedid */
}

.logo-el {
  color: #00cec9;
  font-size: 32px; 
  animation: jumpEffect 2s infinite;
}

@keyframes jumpEffect {
  0%, 100% {
    transform: translateY(0) scale(1);
  }
  40% {
    transform: translateY(-20px) scale(1.1);
  }
  60% {
    transform: translateY(0) scale(0.95);
  }
  80% {
    transform: translateY(-5px) scale(1);
  }
}

.admin-logo-container {
  position: relative;
  border-bottom: none !important;
  box-shadow: none !important;
  outline: none !important;
  padding-bottom: 0;
  margin-bottom: 0;
}

.admin-logo-container::after,
.admin-logo-container::before {
  display: none !important;
  content: none !important;
  border: none !important;
}

/* تبويبات الإدارة */
.admin-tabs {
  list-style: none;
  padding: 0;
  margin: 0;
  margin-top: 5px;
  border-top: none !important;
}

/* إزالة جميع الخطوط من جميع العناصر في الشريط الجانبي */
.admin-sidebar {
  padding-top: 10px;
}

.admin-sidebar *,
.admin-sidebar *::before,
.admin-sidebar *::after {
  border-bottom: none !important;
}

/* تحسين موضع الكلمات في الشعار */
.logo-gedid, .logo-el {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0 2px;
}
