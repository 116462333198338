/* أنماط التحميل */
.loading-overlay {
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(255, 255, 255, 0.8);
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  z-index: 100;
  margin-top: 10px;
}

.dark-mode .loading-overlay {
  background-color: rgba(42, 42, 42, 0.8);
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-radius: 50%;
  border-top: 5px solid var(--primary-color);
  -webkit-animation: spin 1s linear infinite;
  animation: spin 1s linear infinite;
}

.loading-overlay p {
  margin-top: 15px;
  font-weight: 500;
  color: var(--primary-color);
}

@-webkit-keyframes spin {
  0% { -webkit-transform: rotate(0deg); }
  100% { -webkit-transform: rotate(360deg); }
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

/* تعديل موضع مؤشر التحميل في المعاينة */
.post-preview-container .loading-overlay {
  position: fixed;
  z-index: 1000;
}
