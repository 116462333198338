.category-page {
  padding: 2rem;
  min-height: 100vh;
  background-color: var(--background);
}

.category-page-header {
  text-align: center;
  margin-bottom: 2rem;
}

.category-page-title {
  font-size: 2rem;
  font-weight: 700;
  color: var(--text);
  margin: 0;
}

.category-page-content {
  max-width: 1200px;
  margin: 0 auto;
}

.posts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 2rem;
}

/* الوضع الليلي */
.dark-mode.category-page {
  background-color: var(--background-dark);
}

.dark-mode .category-page-title {
  color: var(--text-dark);
}

/* تحسينات للشاشات الصغيرة */
@media (max-width: 768px) {
  .category-page {
    padding: 1rem;
  }

  .posts-grid {
    grid-template-columns: 1fr;
  }

  .category-page-title {
    font-size: 1.75rem;
  }
}
