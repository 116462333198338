/* إضافة متغيرات CSS للتحكم في مراحل الانتقال */
:root {
  --scroll-progress: 0;
  --header-height: calc(80px - (var(--scroll-progress) * 18px));
  --header-padding: calc(25px - (var(--scroll-progress) * 5px));
  --logo-height: calc(75px - (var(--scroll-progress) * 15px));
  --header-shadow: rgba(0, 0, 0, calc(0.05 * var(--scroll-progress)));
  --admin-btn-padding: calc(5px - (var(--scroll-progress) * 1px)) calc(14px - (var(--scroll-progress) * 3px));
  --admin-btn-font-size: calc(18px - (var(--scroll-progress) * 4px));
  --theme-toggle-padding: calc(10px - (var(--scroll-progress) * 2px));
  --theme-toggle-font-size: calc(28px - (var(--scroll-progress) * 4px));
}

.header {
  width: 100%;
  background-color: #ffffff;
  position: fixed;
  box-shadow: 0 0 10px var(--header-shadow);
  border-bottom: 1px solid #eee;
  top: 0;
  left: 0;
  z-index: 1000;
  transition: box-shadow 0.2s ease, background-color 0.3s ease, background-image 0.3s ease, border-color 0.3s ease;
  transform: translateZ(0);
  will-change: transform, height;
  backface-visibility: hidden;
  perspective: 1000;
  
  /* إضافة تعبئة وتلوين خلفي للمساحة البيضاء */
  padding-bottom: 2px;
  background-image: linear-gradient(to bottom, #ffffff 98%, transparent 100%);
}

/* إضافة طبقة ظل خفيفة خلف الهيدر */
.header::after {
  content: '';
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: #ffffff;
  z-index: -1;
  box-shadow: 0 0 10px rgba(0,0,0,0.1);
  opacity: var(--scroll-progress);
  transition: opacity 0.3s ease, background-color 0.3s ease;
}

.header-container {
  width: 100%;
  margin: 0;
  padding: var(--header-padding) 0;
  display: flex;
  justify-content: space-between;
  align-items: center;
  height: var(--header-height);
  position: relative;
  overflow: visible;
  backface-visibility: hidden;
  background-color: transparent;
  transition: background-color 0.3s ease;
}

/* نحتفظ بهذا للمتصفحات القديمة التي لا تدعم متغيرات CSS */
.scrolled .header-container {
  height: 62px;
  padding: 20px 0;
}

.logo-container {
  display: flex;
  justify-content: flex-start;
  align-items: center;
  position: absolute;
  left: 10cm;
  transform: translateY(5px);
  transition: transform 0.3s ease;
  background-color: transparent;
}

.logo {
  max-height: var(--logo-height);
  width: auto;
  transform: scale(calc(1 - (var(--scroll-progress) * 0.02)));
}

/* نحتفظ بهذا للمتصفحات القديمة التي لا تدعم متغيرات CSS */
.scrolled .logo {
  max-height: 60px;
}

.header-buttons {
  display: flex;
  align-items: center;
  gap: 15px;
  margin: 0;
  padding: 0;
  position: absolute;
  right: 0.5cm;
  transition: all 0.3s ease;
  background-color: transparent;
}

.admin-btn {
  background-color: transparent;
  color: #1a91ff;
  padding: var(--admin-btn-padding);
  border-radius: 50px;
  font-weight: 700;
  text-decoration: none;
  font-size: var(--admin-btn-font-size);
  border: 2px solid #1a91ff;
  display: flex;
  align-items: center;
  gap: 6px;
}

/* نحتفظ بهذا للمتصفحات القديمة التي لا تدعم متغيرات CSS */
.scrolled .admin-btn {
  padding: 4px 11px;
  font-size: 14px;
}

.admin-btn:hover {
  background-color: rgba(26, 145, 255, 0.1);
}

.theme-toggle {
  background-color: transparent;
  color: #666;
  border: none;
  padding: var(--theme-toggle-padding);
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: var(--theme-toggle-font-size);
  transition: all 0.3s ease;
  border-radius: 0;
  background-image: none;
  width: auto;
  height: auto;
  position: relative;
  overflow: visible;
  transform: translateY(3px);
}

/* تحسين مظهر زر التبديل عند الحوم بالماوس */
.theme-toggle:hover {
  transform: scale(1.1);
  color: #1a91ff;
  background-color: transparent;
  box-shadow: none;
}

.theme-toggle:focus {
  outline: none;
  box-shadow: none;
}

/* نحتفظ بهذا للمتصفحات القديمة التي لا تدعم متغيرات CSS */
.scrolled .theme-toggle {
  padding: 8px;
  font-size: 24px;
  width: auto;
  height: auto;
}

/* تحسين مظهر زر التبديل في الوضع الليلي */
.dark-mode .theme-toggle {
  color: #fff;
}

.dark-mode .theme-toggle:hover {
  color: #00C3AD;
  transform: scale(1.1);
}

.logout-btn {
  background-color: transparent;
  color: var(--primary-text-color);
  border: none;
  cursor: pointer;
  font-size: 1.1rem;
  transition: all 0.3s ease;
  padding: 0.5rem;
  margin-right: 1rem;
  border-radius: 50%;
  width: auto;
  height: auto;
  position: relative;
  overflow: visible;
  transform: translateY(3px);
}

.logout-btn:hover {
  transform: scale(1.1);
  color: #e74c3c;
  background-color: transparent;
  box-shadow: none;
}

.logout-btn:focus {
  outline: none;
  box-shadow: none;
}

/* ======= تنسيق الوضع الليلي للهيدر بالكامل ======= */
.dark-mode .header {
  background-color: var(--background);
  background-image: linear-gradient(to bottom, var(--background) 98%, transparent 100%);
  border-bottom: 1px solid rgba(255, 255, 255, 0.1);
}

.dark-mode .header::after {
  background-color: var(--background);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.dark-mode .header-container {
  background-color: var(--background);
}

.dark-mode .logo-container {
  background-color: transparent;
}

.dark-mode .header-buttons {
  background-color: transparent;
}

.dark-mode .admin-btn {
  color: #00C3AD;
  border-color: #00C3AD;
}

.dark-mode .admin-btn:hover {
  background-color: rgba(0, 195, 173, 0.2);
  box-shadow: 0 0 10px rgba(0, 195, 173, 0.5);
}

/* مساحة لمحتوى الصفحة ليظهر أسفل الهيدر الثابت */
body {
  padding-top: 80px; /* يساوي ارتفاع الهيدر */
}

/* تخطيط للشاشات الصغيرة */
@media (max-width: 768px) {
  :root {
    --header-height: calc(120px - (var(--scroll-progress) * 20px));
    --logo-height: calc(65px - (var(--scroll-progress) * 10px));
  }
  
  .logo-container {
    margin: 15px auto;
    position: static;
  }
  
  .header-container {
    flex-direction: column-reverse;
    padding: 20px;
    height: auto;
  }
  
  .header-buttons {
    width: 100%;
    justify-content: center;
    margin: 20px auto;
    position: static;
  }
  
  body {
    padding-top: 120px; /* زيادة المساحة للهيدر المتغير */
  }
  
  .header {
    position: fixed;
  }
}

/* إضافة تنسيقات قائمة الأقسام */
.categories-nav {
  margin-right: 15px;
  transition: all 0.3s ease;
}

.categories-list {
  display: flex;
  list-style: none;
  padding: 0;
  margin: 0;
  gap: 15px;
}

.category-item {
  position: relative;
}

.category-link {
  color: #333;
  text-decoration: none;
  font-size: calc(18px - (var(--scroll-progress) * 2px));
  font-weight: 600;
  transition: color 0.3s ease, transform 0.2s ease;
  padding: 5px 8px;
  border-radius: 4px;
  position: relative;
}

.category-link:hover {
  color: #1E90FF;
  transform: translateY(-2px);
}

.category-link::after {
  content: '';
  position: absolute;
  bottom: -2px;
  right: 0;
  width: 0;
  height: 2px;
  background-color: #1E90FF;
  transition: width 0.3s ease;
}

.category-link:hover::after {
  width: 100%;
}

/* تنسيقات الوضع المظلم */
.dark-mode .header {
  background-color: #222;
  border-bottom-color: #333;
  background-image: linear-gradient(to bottom, #222 98%, transparent 100%);
  box-shadow: 0 0 10px rgba(0, 0, 0, 0.2);
}

.dark-mode .header::after {
  background-color: #222;
}

.dark-mode .category-link {
  color: #eee;
}

.dark-mode .category-link:hover {
  color: #40E0D0;
}

.dark-mode .category-link::after {
  background-color: #40E0D0;
}

/* تعديلات للشاشات الصغيرة */
@media (max-width: 768px) {
  .categories-list {
    gap: 10px;
  }
  
  .category-link {
    font-size: calc(16px - (var(--scroll-progress) * 2px));
    padding: 4px 6px;
  }
}

@media (max-width: 576px) {
  .categories-nav {
    margin-right: 5px;
  }
  
  .categories-list {
    gap: 5px;
  }
  
  .category-link {
    font-size: calc(14px - (var(--scroll-progress) * 2px));
    padding: 3px 4px;
  }
}

/* تعديل النمط الخاص بزر تحرير التبويبات */
.toggle-edit-tabs-button {
  background-color: transparent;
  color: #ff6b1a;
  padding: var(--admin-btn-padding);
  border-radius: 50px;
  font-weight: 700;
  text-decoration: none;
  font-size: var(--admin-btn-font-size);
  border: 2px solid #ff6b1a;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: all 0.3s ease;
}

.toggle-edit-tabs-button:hover {
  background-color: #ff6b1a;
  color: white;
}

/* نحتفظ بهذا للمتصفحات القديمة التي لا تدعم متغيرات CSS */
.scrolled .toggle-edit-tabs-button {
  padding: 4px 11px;
  font-size: 14px;
}

/* تحسين مظهر زر التبديل في الوضع الليلي */
.dark-mode .toggle-edit-tabs-button {
  color: #fff;
  border-color: #fff;
}

.dark-mode .toggle-edit-tabs-button:hover {
  background-color: #ff6b1a;
  color: #fff;
}

/* تأثيرات حركة لأيقونات زر تبديل الوضع */
@keyframes spin-slow {
  from { transform: rotate(0deg); }
  to { transform: rotate(360deg); }
}

@keyframes rotate-slow {
  0% { transform: rotate(0deg); }
  50% { transform: rotate(-20deg); }
  100% { transform: rotate(0deg); }
}

.fa-spin-slow {
  animation: spin-slow 10s linear infinite;
  transform-origin: center;
}

.fa-rotate-slow {
  animation: rotate-slow 5s ease-in-out infinite;
  transform-origin: center;
}

.theme-toggle:hover .fa-spin-slow {
  animation-duration: 5s;
}

.theme-toggle:hover .fa-rotate-slow {
  animation-duration: 2s;
}
