/* أنماط التطبيق */

/* متغيرات الألوان الأساسية */
:root {
  --primary: #0088cc;
  --primary-hover: #006da3;
  --secondary: #17a2b8;
  --secondary-hover: #138496;
  --light-bg: #f5f5f5;
  --light-text: #333;
  --dark-bg: #1a1a1a;
  --dark-text: #f5f5f5;
  --dark-secondary-bg: #252525;
  --light-secondary-bg: #ffffff;
  --transition-speed: 0.3s;
}

body {
  transition: background-color var(--transition-speed) ease, color var(--transition-speed) ease;
}

/* إضافة أنماط عامة للوضع الداكن والفاتح */
body.dark-mode {
  background-color: var(--dark-bg);
  color: var(--dark-text);
}

body.light-mode {
  background-color: var(--light-bg);
  color: var(--light-text);
}

.app {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  transition: background-color var(--transition-speed) ease, color var(--transition-speed) ease;
  overflow-x: hidden;
}

.app.dark-mode, body.dark-mode .app {
  background-color: var(--dark-bg);
  color: var(--dark-text);
}

.app.light-mode, body.light-mode .app {
  background-color: var(--light-bg);
  color: var(--light-text);
}

.main-content {
  flex: 1;
  padding: 0;
  width: 100%;
  overflow-x: hidden;
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  border-bottom: none !important;
  transition: background-color var(--transition-speed) ease;
}

.dark-mode .main-content {
  background-color: var(--dark-bg);
}

.light-mode .main-content {
  background-color: var(--light-bg);
}

/* إزالة أي حدود أو خطوط قبل الفوتر */
.main-content::after {
  display: none !important;
  content: none !important;
}

/* الصورة تحت الهيدر */
.banner-image {
  width: 100%;
  max-height: 300px;
  object-fit: cover;
  margin-bottom: 20px;
}

/* أنماط الأزرار */
.btn {
  background-color: var(--primary);
  color: white;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  cursor: pointer;
  font-family: 'Cairo', sans-serif;
  transition: background-color var(--transition-speed) ease;
}

.btn:hover {
  background-color: var(--primary-hover);
}

/* تخطيط للشاشات الصغيرة */
@media (max-width: 768px) {
  .main-content {
    padding: 10px;
  }
}
