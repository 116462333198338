.banner {
  position: relative;
  width: 100vw;
  height: 470px; /* زيادة الارتفاع لمنع قطع الصورة من الأسفل */
  margin-left: calc(-50vw + 50%);
  margin-right: calc(-50vw + 50%);
  margin-top: -45px; /* تعديل القيمة لتجنب التداخل مع الهيدر */
  overflow: visible; /* تغيير من hidden إلى visible لمنع قطع محتوى البانر */
  left: 0;
  right: 0;
  transform: translateZ(0); /* تفعيل تسريع الهاردوير */
  will-change: transform; /* تحسين الأداء */
  -webkit-transform: translateZ(0);
  backface-visibility: hidden; /* إضافة الخاصية القياسية */
  -webkit-backface-visibility: hidden;
  z-index: 10; /* قيمة أقل من الهيدر */
  
  /* إضافة تأثير تغطية للمساحة البيضاء */
  box-shadow: 0 -2px 0 #fff;
}

.banner::before {
  content: "";
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 150%; /* ضمان تغطية كاملة للصورة */
  background-image: url('/logo/paner.png');
  background-size: 100%; /* إعادة إلى الإعداد القديم للتغطية الكاملة للعرض */
  background-position: center 40px; /* إعادة إلى الموضع القديم للصورة */
  background-repeat: no-repeat;
  transform: translateZ(0); /* تفعيل تسريع الهاردوير */
  backface-visibility: hidden; /* إضافة الخاصية القياسية */
  -webkit-transform: translateZ(0);
  -webkit-backface-visibility: hidden;
  /* إضافة خاصية التخزين المؤقت لتحسين الأداء */
  will-change: transform;
  /* تفعيل تخزين الصورة في ذاكرة التخزين المؤقت للمتصفح */
  image-rendering: -webkit-optimize-contrast;
  image-rendering: optimizeQuality;
}

.banner::after {
  display: none; /* إزالة هذا العنصر الذي قد يسبب قطع الصورة */
}

/* إضافة طبقة شفافة فوق الصورة */
.banner-overlay {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: calc(100% + 13px); /* زيادة ارتفاع الفلتر بمقدار 0.13 سم (13px) للأسفل */
  background-color: rgba(0, 0, 0, 0.1); /* شفافية 90% */
  z-index: 1;
}

/* محتوى البانر */
.banner-content {
  position: relative;
  z-index: 2;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 100%;
  text-align: center;
  color: white;
  padding: 0 20px;
}

/* عنوان البانر */
.banner-title {
  font-size: 3rem;
  margin-top: 80px; /* زيادة الهامش العلوي لتنزيل العنوان الرئيسي أكثر للأسفل */
  margin-bottom: -45px; /* تعديل الهامش السفلي للحفاظ على موضع العناصر الأخرى */
  text-shadow: 2px 2px 4px rgba(0, 0, 0, 0.5);
}

/* العنوان الفرعي للبانر */
.banner-subtitle {
  font-size: 1.5rem;
  margin-top: 50px; /* زيادة الهامش العلوي من 30px إلى 50px لتنزيل العنوان الفرعي أكثر */
  margin-bottom: 35px; /* تقليل الهامش السفلي للحفاظ على موضع شريط البحث كما هو */
  text-shadow: 1px 1px 3px rgba(0, 0, 0, 0.5);
}

/* إضافة استجابة للشاشات المختلفة */
@media (max-width: 768px) {
  .banner {
    height: 400px;
  }
  
  .banner::before {
    background-size: 100%;  /* الحفاظ على عرض الصورة بنسبة 100% */
    background-position: center 28px; /* تعديل موضع الصورة للأعلى في الشاشات الصغيرة */
  }
  
  .banner-title {
    font-size: 2.2rem;
    margin-top: 60px;
  }
  
  .banner-subtitle {
    font-size: 1.2rem;
  }
  
  .search-container {
    max-width: 85%;
  }
}

/* تخصيص للشاشات الصغيرة جدًا مثل الهواتف */
@media (max-width: 480px) {
  .banner {
    height: 350px;
  }
  
  .banner::before {
    background-size: 100%;  /* الحفاظ على عرض الصورة بنسبة 100% */
    background-position: center 20px; /* تعديل موضع الصورة للأعلى أكثر في الشاشات الصغيرة جدًا */
  }
  
  .banner-title {
    font-size: 1.8rem;
    margin-top: 50px;
  }
  
  .banner-subtitle {
    font-size: 1rem;
    margin-top: 40px;
  }
  
  .search-input {
    padding: 10px 20px;
  }
  
  .search-button {
    padding: 10px 15px;
  }
}

/* حاوية البحث - شفافة دائمًا بالكامل */
.search-container {
  display: flex;
  width: 100%;
  max-width: 500px;
  position: relative;
  border-radius: 8px;
  overflow: hidden;
  transition: all 0.3s ease;
  background-color: transparent !important;
  background: transparent !important;
  border: none !important;
}

/* حقل البحث - شفاف تمامًا */
.search-input {
  flex: 1;
  padding: 13px 24px;
  border: none !important;
  outline: none !important;
  font-size: 1.1rem;
  font-weight: 500;
  background-color: transparent !important;
  background: transparent !important;
  color: white;
  direction: rtl;
  border-radius: 5px;
  transition: all 0.3s ease;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
}

/* تنسيق نص المؤشر البديل */
.search-input::placeholder {
  color: rgba(255, 255, 255, 0.8);
}

/* منع تغيير لون الخلفية عند الملء التلقائي */
.search-input:-webkit-autofill,
.search-input:-webkit-autofill:hover,
.search-input:-webkit-autofill:focus {
  -webkit-text-fill-color: white !important;
  transition: background-color 5000s ease-in-out 0s;
  background-color: transparent !important;
  box-shadow: 0 0 0px 1000px transparent inset !important;
}

/* القواعد المشتركة لكلا الوضعين للتفاعل (hover) والتركيز (focus) */
.search-input:focus-visible,
.search-container.light .search-input:focus,
.search-container.dark .search-input:focus,
.search-container.light .search-input:hover,
.search-container.dark .search-input:hover {
  outline: none !important;
  border: none !important;
  box-shadow: none !important;
  -webkit-box-shadow: none !important;
  background-color: transparent !important;
}

/* حالة التفاعل النشط للحاويات */
.search-container.active {
  border: none !important;
  outline: none !important;
}

.search-container.light {
  box-shadow: 0 0 12px 3px rgba(30, 144, 255, 0.4);
  animation: pulsate-light 3s ease-in-out infinite alternate;
}

.search-container.light.active {
  box-shadow: 0 0 30px 8px rgba(30, 144, 255, 0.9);
  animation: none;
}

.search-container.dark {
  box-shadow: 0 0 12px 3px rgba(64, 224, 208, 0.4);
  animation: pulsate-dark 3s ease-in-out infinite alternate;
}

.search-container.dark.active {
  box-shadow: 0 0 30px 8px rgba(64, 224, 208, 0.9);
  animation: none;
}

/* زر البحث - شفاف تمامًا */
.search-button {
  width: 50px;
  border: none !important;
  outline: none !important;
  background-color: transparent !important;
  background: transparent !important;
  backdrop-filter: blur(3px);
  -webkit-backdrop-filter: blur(3px);
  color: white;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s ease;
  font-size: 1.2rem;
}

/* تعريف أنيميشن نبض للوضع النهاري */
@keyframes pulsate-light {
  0% {
    box-shadow: 0 0 12px 3px rgba(30, 144, 255, 0.4);
  }
  50% {
    box-shadow: 0 0 20px 5px rgba(30, 144, 255, 0.6);
  }
  100% {
    box-shadow: 0 0 30px 8px rgba(30, 144, 255, 0.8);
  }
}

/* تعريف أنيميشن نبض للوضع الليلي */
@keyframes pulsate-dark {
  0% {
    box-shadow: 0 0 12px 3px rgba(64, 224, 208, 0.4);
  }
  50% {
    box-shadow: 0 0 20px 5px rgba(64, 224, 208, 0.6);
  }
  100% {
    box-shadow: 0 0 30px 8px rgba(64, 224, 208, 0.8);
  }
}

/* تأثير الزر عند الضغط */
.search-button:active {
  transform: scale(0.95);
}
