/* تنسيقات صفحة المنشور */
.post-page {
  width: 100%;
  padding: 120px 0 60px;
  min-height: 100vh;
  background-color: #f9f9f9;
  transition: background-color 0.3s ease;
}

.post-container {
  width: 100%;
  max-width: 1200px; /* تم زيادة العرض لمطابقة عرض الكارت الكبير */
  margin: 0 auto;
  background-color: #fff;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  border-radius: 15px;
  padding: 40px;
  position: relative;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

/* نمط الوضع النهاري */
.light-mode .post-container {
  background-color: #f8f8f8;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

/* نمط الوضع الليلي */
.dark-mode .post-container {
  background-color: #333333;
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

/* تأثير التفاعل عند المرور بالماوس */
.post-container:hover {
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.12);
}

.dark-mode .post-container:hover {
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.25);
}

.post-header {
  margin-bottom: 30px;
  text-align: right;
}

.post-title {
  font-size: 2.5rem;
  margin-bottom: 20px;
  line-height: 1.3;
  color: #333;
  font-weight: 700;
  transition: color 0.3s ease;
}

/* تنسيق عنوان المنشور في الوضع الليلي */
.dark-mode .post-title {
  color: #f0f0f0;
}

.light-mode .post-title {
  color: #1E90FF;
}

.post-meta {
  display: flex;
  gap: 20px;
  margin-bottom: 15px;
  color: #666;
  font-size: 0.95rem;
}

.post-date, .post-category {
  display: flex;
  align-items: center;
  gap: 8px;
}

.meta-icon {
  color: #1E90FF;
  transition: color 0.3s ease;
}

.post-category a {
  color: #1E90FF;
  text-decoration: none;
  transition: color 0.3s ease;
}

.post-category a:hover {
  color: #0066cc;
  text-decoration: underline;
}

.post-author {
  font-style: italic;
  color: #777;
  margin-bottom: 20px;
}

.post-featured-image {
  width: 100%;
  margin-bottom: 30px;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.08);
  max-height: 500px; /* تحديد الارتفاع الأقصى للصورة البارزة */
  background-color: #f0f0f0; /* لون خلفية في حالة تأخر تحميل الصورة */
}

.post-featured-image img {
  width: 100%;
  height: 100%;
  max-height: 500px;
  object-fit: contain; /* لضمان ظهور الصورة كاملة دون اقتصاص */
  display: block;
  transition: transform 0.3s ease;
}

.post-featured-image:hover img {
  transform: scale(1.02);
}

.post-content {
  line-height: 1.8;
  font-size: 1.1rem;
  color: #444;
  text-align: right;
  margin-bottom: 40px;
  transition: color 0.3s ease;
}

/* تنسيق الصور داخل محتوى المنشور */
.post-content img {
  max-width: 100%;
  height: auto;
  border-radius: 8px;
  margin: 20px 0;
  display: block;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.1);
}

.post-content h2 {
  font-size: 1.8rem;
  margin: 30px 0 15px;
  color: #222;
  font-weight: 600;
  transition: color 0.3s ease;
}

.post-content p {
  margin-bottom: 20px;
}

.post-content ul, .post-content ol {
  margin: 20px 30px;
  padding: 0;
}

.post-content li {
  margin-bottom: 10px;
}

.post-tags {
  margin-top: 30px;
  border-top: 1px solid #eee;
  padding-top: 20px;
  display: flex;
  align-items: flex-start;
  flex-wrap: wrap;
  gap: 10px;
  transition: border-color 0.3s ease;
}

.tags-label {
  font-weight: 600;
  color: #555;
  margin-left: 10px;
  transition: color 0.3s ease;
}

.tags-list {
  display: flex;
  flex-wrap: wrap;
  gap: 8px;
}

.tag {
  display: inline-block;
  background-color: #f0f0f0;
  color: #555;
  padding: 5px 12px;
  border-radius: 20px;
  font-size: 0.9rem;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.tag:hover {
  background-color: #e0e0e0;
}

.related-posts {
  margin-top: 50px;
  border-top: 1px solid #eee;
  padding-top: 30px;
  transition: border-color 0.3s ease;
}

.related-title {
  font-size: 1.6rem;
  margin-bottom: 20px;
  color: #333;
  font-weight: 600;
  text-align: right;
  transition: color 0.3s ease;
}

.related-posts-grid {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(250px, 1fr));
  gap: 25px;
}

.related-post-card {
  background-color: #fff;
  border-radius: 8px;
  overflow: hidden;
  box-shadow: 0 3px 10px rgba(0, 0, 0, 0.05);
  transition: transform 0.3s ease, box-shadow 0.3s ease, background-color 0.3s ease;
}

.related-post-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.1);
}

.related-post-card a {
  text-decoration: none;
  color: inherit;
}

.related-post-image {
  width: 100%;
  height: 150px;
  overflow: hidden;
}

.related-post-image img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  transition: transform 0.3s ease;
}

.related-post-card:hover .related-post-image img {
  transform: scale(1.05);
}

.related-post-title {
  padding: 15px;
  margin: 0;
  font-size: 1rem;
  line-height: 1.4;
  color: #333;
  transition: color 0.3s ease;
}

.post-not-found {
  text-align: center;
  padding: 50px 20px;
}

.post-not-found h2 {
  font-size: 1.8rem;
  margin-bottom: 15px;
  color: #e74c3c;
}

.post-not-found p {
  font-size: 1.1rem;
  margin-bottom: 25px;
  color: #555;
}

.back-to-home {
  display: inline-flex;
  align-items: center;
  gap: 10px;
  background-color: #1E90FF;
  color: white;
  padding: 10px 20px;
  border-radius: 5px;
  text-decoration: none;
  font-weight: 600;
  transition: background-color 0.3s ease;
}

.back-to-home:hover {
  background-color: #0066cc;
}

.icon-flip-rtl {
  transform: scaleX(-1);
}

.loading-spinner {
  width: 50px;
  height: 50px;
  border: 5px solid #f3f3f3;
  border-top: 5px solid #1E90FF;
  border-radius: 50%;
  margin: 0 auto 20px;
  animation: spin 1s linear infinite;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.loading-text {
  text-align: center;
  font-size: 1.1rem;
  color: #666;
}

/* تنسيقات الوضع المظلم */
.dark-mode.post-page {
  background-color: #1a1a1a;
}

.dark-mode .post-container {
  background-color: #222;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.2);
}

.dark-mode .post-title {
  color: #e0e0e0;
}

.dark-mode .post-meta {
  color: #aaa;
}

.dark-mode .meta-icon {
  color: #40E0D0;
}

.dark-mode .post-category a {
  color: #40E0D0;
}

.dark-mode .post-category a:hover {
  color: #30c0b0;
}

.dark-mode .post-author {
  color: #999;
}

.dark-mode .post-content {
  color: #ccc;
}

.dark-mode .post-content h2 {
  color: #e0e0e0;
}

.dark-mode .post-tags {
  border-top-color: #333;
}

.dark-mode .tags-label {
  color: #bbb;
}

.dark-mode .tag {
  background-color: #333;
  color: #ccc;
}

.dark-mode .tag:hover {
  background-color: #444;
}

.dark-mode .related-posts {
  border-top-color: #333;
}

.dark-mode .related-title {
  color: #e0e0e0;
}

.dark-mode .related-post-card {
  background-color: #2a2a2a;
}

.dark-mode .related-post-title {
  color: #ddd;
}

.dark-mode .back-to-home {
  background-color: #40E0D0;
}

.dark-mode .back-to-home:hover {
  background-color: #30c0b0;
}

.dark-mode .loading-spinner {
  border-color: #333;
  border-top-color: #40E0D0;
}

.dark-mode .loading-text {
  color: #aaa;
}

/* تعديلات للشاشات الصغيرة */
@media (max-width: 768px) {
  .post-container {
    width: 95%;
    padding: 25px;
  }
  
  .post-title {
    font-size: 1.8rem;
  }
  
  .post-meta {
    flex-direction: column;
    gap: 10px;
  }
  
  .post-content {
    font-size: 1rem;
  }
  
  .post-content h2 {
    font-size: 1.5rem;
  }
  
  .related-posts-grid {
    grid-template-columns: 1fr;
  }
}

@media (max-width: 480px) {
  .post-container {
    padding: 20px 15px;
  }
  
  .post-title {
    font-size: 1.5rem;
  }
  
  .post-featured-image {
    margin-right: -15px;
    margin-left: -15px;
    width: calc(100% + 30px);
    border-radius: 0;
  }
}

/* تنسيقات قسم التعليقات */
.post-comments-section {
  margin-top: 50px;
  border-top: 1px solid #eee;
  padding-top: 30px;
  transition: border-color 0.3s ease;
}

.comments-title {
  font-size: 1.6rem;
  margin-bottom: 20px;
  color: #333;
  font-weight: 600;
  text-align: right;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 10px;
  transition: color 0.3s ease;
}

.comments-icon {
  color: #1E90FF;
  transition: color 0.3s ease;
}

.comments-list {
  margin-bottom: 30px;
}

.comment-item {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  margin-bottom: 20px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.05);
}

.comment-item:hover {
  box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1);
}

.comment-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  transition: border-color 0.3s ease;
}

.comment-author, .comment-date, .reply-author, .reply-date {
  display: flex;
  align-items: center;
  gap: 8px;
  color: #555;
  font-size: 0.9rem;
  transition: color 0.3s ease;
}

.comment-icon, .reply-icon {
  color: #1E90FF;
  transition: color 0.3s ease;
}

.comment-content, .reply-content {
  line-height: 1.6;
  color: #444;
  text-align: right;
  margin-bottom: 15px;
  transition: color 0.3s ease;
}

.reply-button {
  background-color: #f0f0f0;
  border: none;
  border-radius: 20px;
  padding: 5px 12px;
  cursor: pointer;
  display: flex;
  align-items: center;
  gap: 8px;
  font-size: 0.85rem;
  color: #555;
  margin-right: auto;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.reply-button:hover {
  background-color: #e0e0e0;
  color: #333;
}

.comment-replies {
  margin-top: 15px;
  margin-right: 20px;
  border-right: 2px solid #eee;
  padding-right: 20px;
  transition: border-color 0.3s ease;
}

.reply-item {
  background-color: #f5f5f5;
  border-radius: 8px;
  padding: 15px;
  margin-bottom: 15px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
}

.reply-header {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
  border-bottom: 1px solid #eee;
  padding-bottom: 10px;
  transition: border-color 0.3s ease;
}

.reply-form {
  margin-top: 15px;
  background-color: #f0f0f0;
  border-radius: 8px;
  padding: 15px;
  transition: background-color 0.3s ease;
}

.no-comments {
  text-align: center;
  padding: 30px;
  background-color: #f9f9f9;
  border-radius: 10px;
  color: #777;
  font-style: italic;
  transition: background-color 0.3s ease, color 0.3s ease;
}

.add-comment-form {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 25px;
  transition: background-color 0.3s ease, box-shadow 0.3s ease;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.05);
}

.add-comment-form h4 {
  font-size: 1.3rem;
  margin-bottom: 20px;
  color: #333;
  text-align: right;
  transition: color 0.3s ease;
}

.form-group {
  margin-bottom: 20px;
}

.form-group label {
  display: block;
  margin-bottom: 8px;
  color: #444;
  font-weight: 500;
  text-align: right;
  transition: color 0.3s ease;
}

.form-group input, .form-group textarea {
  width: 100%;
  padding: 12px;
  border: 1px solid #ddd;
  border-radius: 6px;
  font-size: 1rem;
  direction: rtl;
  transition: border-color 0.3s ease, box-shadow 0.3s ease;
}

.form-group input:focus, .form-group textarea:focus {
  outline: none;
  border-color: #1E90FF;
  box-shadow: 0 0 5px rgba(30, 144, 255, 0.2);
}

.submit-comment, .submit-reply {
  background-color: #1E90FF;
  color: white;
  border: none;
  border-radius: 6px;
  padding: 12px 25px;
  font-size: 1rem;
  cursor: pointer;
  transition: background-color 0.3s ease, transform 0.2s ease;
}

.submit-comment:hover, .submit-reply:hover {
  background-color: #0066cc;
  transform: translateY(-2px);
}

.submit-comment:disabled, .submit-reply:disabled {
  background-color: #ccc;
  cursor: not-allowed;
  transform: none;
}

.comment-notice {
  font-size: 0.85rem;
  color: #777;
  margin-top: 10px;
  text-align: right;
  font-style: italic;
  transition: color 0.3s ease;
}

/* رسالة عدم السماح بالتعليقات */
.comments-disabled-message {
  background-color: #f8f9fa;
  border-radius: 8px;
  padding: 20px;
  margin-top: 20px;
  border-right: 4px solid #dc3545;
  text-align: right;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.05);
  transition: all 0.3s ease;
}

.comments-disabled-message p {
  color: #721c24;
  font-size: 1.1rem;
  margin: 0;
}

.dark-mode .comments-disabled-message {
  background-color: #2a2a2a;
  border-right-color: #FF4136;
}

.dark-mode .comments-disabled-message p {
  color: #ff6b6b;
}

/* تنسيقات الوضع المظلم لقسم التعليقات */
.dark-mode .post-comments-section {
  border-top-color: #444;
}

.dark-mode .comments-title,
.dark-mode .add-comment-form h4 {
  color: #eee;
}

.dark-mode .comments-icon,
.dark-mode .comment-icon,
.dark-mode .reply-icon {
  color: #4da6ff;
}

.dark-mode .comment-item,
.dark-mode .add-comment-form {
  background-color: #333;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
}

.dark-mode .comment-header,
.dark-mode .reply-header {
  border-bottom-color: #555;
}

.dark-mode .comment-author,
.dark-mode .comment-date,
.dark-mode .reply-author,
.dark-mode .reply-date {
  color: #bbb;
}

.dark-mode .comment-content,
.dark-mode .reply-content {
  color: #ddd;
}

.dark-mode .reply-button {
  background-color: #444;
  color: #ddd;
}

.dark-mode .reply-button:hover {
  background-color: #555;
  color: #fff;
}

.dark-mode .comment-replies {
  border-right-color: #444;
}

.dark-mode .reply-item {
  background-color: #2c2c2c;
}

.dark-mode .reply-form {
  background-color: #2a2a2a;
}

.dark-mode .no-comments {
  background-color: #333;
  color: #aaa;
}

.dark-mode .form-group label {
  color: #ccc;
}

.dark-mode .form-group input,
.dark-mode .form-group textarea {
  background-color: #444;
  border-color: #555;
  color: #eee;
}

.dark-mode .form-group input:focus,
.dark-mode .form-group textarea:focus {
  border-color: #4da6ff;
  box-shadow: 0 0 5px rgba(77, 166, 255, 0.4);
}

.dark-mode .comment-notice {
  color: #999;
}
