/* تنسيق الصفحة الرئيسية */
.home-page {
  padding: 0;
  margin: 0;
  text-align: right;
  overflow-x: hidden; /* منع الشريط الأفقي */
  position: relative;
  max-width: 100%;
  background-color: #eaeaea; /* لون خلفية رمادي فاتح للصفحة بأكملها في الوضع النهاري */
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
  border-bottom: none !important;
}

/* الوضع الداكن للصفحة بأكملها */
.dark-mode .home-page {
  background-color: #222222;
}

.banner-wrapper {
  width: 100vw;
  margin-left: calc(-50vw + 50%);
  margin-bottom: 2rem;
  position: relative;
}

.content-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 1rem;
  margin-bottom: 0 !important;
  border-bottom: none !important;
}

/* قسم أحدث المقالات */
.featured-posts {
  margin-bottom: 3rem;
  padding: 2rem;
  border-radius: 15px;
  position: relative;
  overflow: visible;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  text-align: center;
  transition: all 0.3s ease;
}

.light-mode .featured-posts {
  border: 1px solid rgba(30, 144, 255, 0.3);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1), 0 0 15px rgba(30, 144, 255, 0.4);
}

.dark-mode .featured-posts {
  border: 1px solid rgba(64, 224, 208, 0.3);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2), 0 0 15px rgba(64, 224, 208, 0.4);
}

.featured-posts:hover {
  transform: translateY(-5px);
}

.light-mode .featured-posts:hover {
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15), 0 0 20px rgba(30, 144, 255, 0.6);
}

.dark-mode .featured-posts:hover {
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3), 0 0 20px rgba(64, 224, 208, 0.6);
}

.featured-posts h2 {
  font-size: 2rem;
  margin-bottom: 1.5rem;
  font-weight: 700;
  position: relative;
  display: inline-block;
}

.light-mode .featured-posts h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  right: 25%;
  width: 50%;
  height: 4px;
  background: linear-gradient(90deg, rgba(30, 144, 255, 0.2), rgba(30, 144, 255, 0.8), rgba(30, 144, 255, 0.2));
  border-radius: 4px;
}

.dark-mode .featured-posts h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  right: 25%;
  width: 50%;
  height: 4px;
  background: linear-gradient(90deg, rgba(64, 224, 208, 0.2), rgba(64, 224, 208, 0.8), rgba(64, 224, 208, 0.2));
  border-radius: 4px;
}

.posts-grid {
  display: grid;
  grid-template-columns: repeat(3, 1fr);
  gap: 2rem;
  margin-top: 2rem;
}

/* حاوية التصنيفات */
.categories-container {
  display: flex;
  flex-direction: column;
  gap: 3rem;
  margin-bottom: 3rem;
}

.categories-container > div:nth-child(even) {
  align-self: flex-start;
}

.categories-container > div:nth-child(odd) {
  align-self: flex-end;
}

/* قسم النشرة البريدية */
.newsletter {
  padding: 2rem;
  border-radius: 15px;
  text-align: center;
  margin-top: 4rem;
  margin-bottom: 3rem; /* زيادة المسافة أسفل النشرة البريدية */
  position: relative;
  overflow: visible;
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  transition: all 0.3s ease;
}

.light-mode .newsletter {
  background-color: #f8f8f8; /* كارت الاشتراك في النشرة البريدية باللون الأبيض الفاتح */
  border: 1px solid rgba(30, 144, 255, 0.3);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1), 0 0 15px rgba(30, 144, 255, 0.4);
}

.dark-mode .newsletter {
  background-color: #333333; /* نفس لون البطاقات الكبيرة في الوضع الداكن */
  border: 1px solid rgba(0, 195, 173, 0.3);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2), 0 0 15px rgba(0, 195, 173, 0.4);
}

.newsletter:hover {
  transform: translateY(-5px);
}

.light-mode .newsletter:hover {
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.15), 0 0 20px rgba(30, 144, 255, 0.6);
}

.dark-mode .newsletter:hover {
  box-shadow: 0 10px 40px rgba(0, 0, 0, 0.3), 0 0 20px rgba(0, 195, 173, 0.6);
}

.newsletter h2 {
  font-size: 1.75rem;
  margin-bottom: 1rem;
  font-weight: 700;
  position: relative;
  display: inline-block;
}

.dark-mode .newsletter h2 {
  color: #00C3AD; /* لون تركواز جديد لعنوان النشرة البريدية */
}

.light-mode .newsletter h2::after {
  content: '';
  position: absolute;
  bottom: -8px;
  right: 25%;
  width: 50%;
  height: 3px;
  background: linear-gradient(90deg, rgba(30, 144, 255, 0.2), rgba(30, 144, 255, 0.8), rgba(30, 144, 255, 0.2));
  border-radius: 3px;
}

.dark-mode .newsletter h2::after {
  content: '';
  position: absolute;
  bottom: -10px;
  right: 25%;
  width: 50%;
  height: 3px;
  background: linear-gradient(90deg, rgba(0, 195, 173, 0.2), rgba(0, 195, 173, 0.8), rgba(0, 195, 173, 0.2));
  border-radius: 3px;
}

.newsletter p {
  margin-bottom: 1.5rem;
  font-size: 1.1rem;
  opacity: 0.9;
  max-width: 600px;
  margin-left: auto;
  margin-right: auto;
}

.dark-mode .newsletter p {
  color: #e0e0e0;
}

.newsletter-form {
  position: relative;
  max-width: 400px;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;
  direction: rtl;
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.newsletter-form .form-group {
  position: relative;
  width: 100%;
}

.newsletter-form input {
  width: 100%;
  height: 50px;
  padding: 10px 25px;
  border: none;
  border-radius: 25px;
  font-size: 14px;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  color: var(--text);
  text-align: right;
  transition: all 0.3s ease;
}

.newsletter-form input::placeholder {
  color: rgba(108, 117, 125, 0.8);
  transition: all 0.3s ease;
}

.light-mode .newsletter-form input {
  border: 2px solid rgba(30, 144, 255, 0.4);
}

.dark-mode .newsletter-form input {
  border: 2px solid rgba(0, 195, 173, 0.4);
  color: white;
}

.dark-mode .newsletter-form input::placeholder {
  color: rgba(255, 255, 255, 0.7);
}

.light-mode .newsletter-form input:focus {
  outline: none;
  box-shadow: 0 0 15px rgba(30, 144, 255, 0.7);
  border-color: rgba(30, 144, 255, 0.8);
}

.dark-mode .newsletter-form input:focus {
  outline: none;
  box-shadow: 0 0 15px rgba(0, 195, 173, 0.7);
  border-color: rgba(0, 195, 173, 0.8);
}

.newsletter-form button {
  width: 150px;
  height: 45px;
  border: none;
  border-radius: 50px;
  font-size: 14px;
  font-weight: 600;
  cursor: pointer;
  transition: all 0.3s ease;
  background-color: rgba(255, 255, 255, 0.1);
  backdrop-filter: blur(10px);
  margin-top: 10px;
  align-self: center;
}

.light-mode .newsletter-form button {
  color: #1E90FF;
  border: 2px solid rgba(30, 144, 255, 0.8);
  background-color: rgba(30, 144, 255, 0.1);
  box-shadow: 0 0 5px rgba(30, 144, 255, 0.4);
}

.dark-mode .newsletter-form button {
  background-color: rgba(0, 195, 173, 0.2);
  color: white;
  border: 2px solid rgba(0, 195, 173, 0.8);
  box-shadow: 0 0 5px rgba(0, 195, 173, 0.4);
}

.dark-mode .newsletter-form button:hover {
  background-color: rgba(0, 195, 173, 0.3);
  box-shadow: 0 0 15px rgba(0, 195, 173, 0.7);
}

.light-mode .newsletter-form button:hover {
  background-color: rgba(30, 144, 255, 0.2);
  box-shadow: 0 0 15px rgba(30, 144, 255, 0.7);
}

/* إضافة تأثير توهج للحقول والزر */
@keyframes glowLight {
  0% { box-shadow: 0 0 5px rgba(30, 144, 255, 0.3); }
  50% { box-shadow: 0 0 15px rgba(30, 144, 255, 0.5); }
  100% { box-shadow: 0 0 5px rgba(30, 144, 255, 0.3); }
}

@keyframes glowDark {
  0% { box-shadow: 0 0 5px rgba(0, 195, 173, 0.3); }
  50% { box-shadow: 0 0 15px rgba(0, 195, 173, 0.5); }
  100% { box-shadow: 0 0 5px rgba(0, 195, 173, 0.3); }
}

.light-mode .newsletter-form input,
.light-mode .newsletter-form button {
  animation: glowLight 3s infinite;
}

.dark-mode .newsletter-form input,
.dark-mode .newsletter-form button {
  animation: glowDark 3s infinite;
}

/* للتأكد من مظهر النموذج على الشاشات الصغيرة */
@media (max-width: 576px) {
  .newsletter-form {
    max-width: 300px;
  }
  
  .newsletter-form input,
  .newsletter-form button {
    font-size: 14px;
  }
}

/* قسم الترحيب */
.welcome-section {
  margin-top: 20px;
  margin-bottom: 40px;
  text-align: center;
}

.welcome-section h1 {
  font-size: 2.5rem;
  margin-bottom: 15px;
  color: #1a91ff;
}

.welcome-text {
  font-size: 1.2rem;
  line-height: 1.6;
  max-width: 800px;
  margin: 0 auto;
}

/* تكيف مع الشاشات المختلفة */
@media (max-width: 1200px) {
  .categories-container > div {
    width: 90%;
    align-self: center !important;
  }
}

@media (max-width: 900px) {
  .posts-grid {
    grid-template-columns: repeat(2, 1fr);
  }
  
  .categories-container > div {
    width: 100%;
  }
}

@media (max-width: 600px) {
  .posts-grid {
    grid-template-columns: 1fr;
  }
}

/* إزالة حد برتقالي بين المحتوى والفوتر */
.home-page::after {
  display: none !important;
  content: none !important;
}

.content-container::after {
  display: none !important;
  content: none !important;
}
