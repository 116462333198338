.category-section {
  margin-top: 80px; /* زيادة الهامش العلوي للمسافة بين البانر والكارت */
  margin-bottom: -50px; /* تقليل المسافة بين الكروت بمقدار 0.25 سم إضافية */
  padding: 1.5rem;
  border-radius: 15px;
  position: relative;
  overflow: visible;
  
  /* تأثير زجاجي عائم */
  background-color: rgba(255, 255, 255, 0.15);
  backdrop-filter: blur(10px);
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.1);
  
  /* تأثير العوم */
  transform: translateY(0);
  transition: transform 0.3s ease;
}

/* نمط الوضع النهاري */
.light-mode .category-section {
  background-color: #f8f8f8; /* الكروت الكبيرة (أقسام التصنيفات) باللون الأبيض الفاتح */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.08);
  border: 1px solid rgba(0, 0, 0, 0.05);
}

.light-mode .category-section h2 {
  color: #1E90FF; /* لون أزرق لأسماء الأقسام في الوضع النهاري */
}

/* نمط الوضع الليلي */
.dark-mode .category-section {
  background-color: #333333; /* تم تغيير لون البطاقات الكبيرة في الوضع الليلي */
  box-shadow: 0 8px 32px rgba(0, 0, 0, 0.2);
  border: 1px solid rgba(255, 255, 255, 0.05);
}

/* تأثير التفاعل عند المرور بالماوس */
.category-section:hover {
  transform: translateY(0); /* تم إلغاء الحركة للأعلى */
  /* يمكن إضافة تأثيرات أخرى هنا مثل تغيير لون الحدود أو الظل */
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.12); /* زيادة الظل فقط للتأكيد على التفاعل */
}

.dark-mode .category-section:hover {
  box-shadow: 0 10px 35px rgba(0, 0, 0, 0.25); /* ظل أغمق في الوضع الليلي */
}

.category-section:last-child {
  margin-bottom: 80px; /* إضافة مسافة بعد آخر كارت */
}

.category-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 1.5rem;
  padding-bottom: 0.75rem;
  border-bottom: 2px solid;
}

.light-mode .category-header {
  border-color: rgba(30, 144, 255, 0.4);
}

.dark-mode .category-header {
  border-color: rgba(0, 195, 173, 0.4);
}

.category-title {
  font-size: 1.75rem;
  font-weight: 700;
  margin: 0;
  color: inherit;
  text-decoration: none;
  position: relative;
  display: inline-block;
  width: 100%;
  text-align: center;
}

.title-link {
  color: inherit;
  text-decoration: none;
  display: block;
  width: 100%;
}

/* إضافة لون تركواز لعناوين الأقسام في الوضع الداكن */
.dark-mode .category-title {
  color: #00C3AD;
}

/* إزالة الخط الصغير تحت العنوان عن طريق إلغاء العناصر الزائفة */
.light-mode .category-title::after, 
.dark-mode .category-title::after {
  display: none;
}

.view-all {
  color: inherit;
  text-decoration: none;
  font-weight: 600;
  font-size: 0.9rem;
  padding: 6px 12px;
  border-radius: 20px;
  transition: all 0.3s ease;
  position: absolute; /* تثبيت موضع الزر */
  left: 1.5rem; /* المسافة من اليسار للزر */
  top: 1.5rem; /* المسافة من الأعلى مطابقة لـ padding العنصر الأب */
}

.light-mode .view-all {
  background-color: rgba(30, 144, 255, 0.1);
}

.dark-mode .view-all {
  background-color: rgba(64, 224, 208, 0.1);
  color: #00C3AD;
}

.light-mode .view-all:hover {
  color: #fff;
  background-color: rgba(30, 144, 255, 0.8);
}

.dark-mode .view-all:hover {
  color: #33D6C2;
  background-color: rgba(0, 195, 173, 0.8);
}

.category-posts {
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  gap: 1.5rem;
}

@media (max-width: 1200px) {
  .category-posts {
    grid-template-columns: repeat(3, 1fr);
  }
}

@media (max-width: 900px) {
  .category-posts {
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: 600px) {
  .category-posts {
    grid-template-columns: 1fr;
  }
  
  .category-section {
    padding: 1rem;
  }
  
  .category-title {
    font-size: 1.75rem;
  }
  
  .view-all {
    top: 1rem; /* تعديل المسافة من الأعلى لتتناسب مع padding في الشاشات الصغيرة */
    left: 1rem; /* تعديل المسافة من اليسار لتتناسب مع padding في الشاشات الصغيرة */
  }
}
