/* تنسيق صفحة تسجيل الدخول */
.login-container {
  max-width: 500px;
  margin: 40px auto;
  padding: 30px;
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  transition: all 0.3s ease;
}

.dark-mode .login-container {
  background-color: #2a2a2a;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
}

.login-form-wrapper h3 {
  color: #0097e6;
  margin-bottom: 25px;
  text-align: center;
  font-size: 24px;
}

.login-form {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-weight: 500;
  color: #555;
}

.dark-mode .form-group label {
  color: #ddd;
}

.form-group input {
  padding: 12px 15px;
  border: 1px solid #ddd;
  border-radius: 8px;
  font-size: 16px;
  transition: all 0.3s ease;
}

.form-group input:focus {
  border-color: #0097e6;
  box-shadow: 0 0 0 2px rgba(0, 151, 230, 0.2);
  outline: none;
}

.dark-mode .form-group input {
  background-color: #333;
  border-color: #444;
  color: #fff;
}

.login-button, .create-account-button {
  background: linear-gradient(135deg, #0097e6, #00cec9);
  color: white;
  padding: 12px 20px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  font-size: 16px;
  font-weight: 600;
  transition: all 0.3s ease;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 10px;
  margin-top: 15px;
}

.login-button:hover, .create-account-button:hover {
  background: linear-gradient(135deg, #00a8ff, #00e2d9);
  transform: translateY(-2px);
  box-shadow: 0 5px 15px rgba(0, 151, 230, 0.3);
}

.error-message {
  color: #e74c3c;
  background-color: rgba(231, 76, 60, 0.1);
  padding: 10px 15px;
  border-radius: 5px;
  margin-bottom: 20px;
  text-align: center;
}

/* تنسيق صفحة إدارة الحسابات */
.accounts-management {
  background-color: #ffffff;
  border-radius: 15px;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.1);
  margin-top: 30px;
  overflow: hidden;
}

.dark-mode .accounts-management {
  background-color: #2a2a2a;
  box-shadow: 0 5px 30px rgba(0, 0, 0, 0.3);
}

.accounts-tabs {
  display: flex;
  background-color: #f5f5f5;
  padding: 15px 20px 0;
  gap: 10px;
  border-bottom: 1px solid #eee;
}

.dark-mode .accounts-tabs {
  background-color: #333;
  border-bottom-color: #444;
}

.account-tab-btn {
  padding: 12px 20px;
  background-color: transparent;
  border: none;
  border-radius: 10px 10px 0 0;
  cursor: pointer;
  font-size: 16px;
  font-weight: 500;
  color: #666;
  transition: all 0.3s ease;
  display: flex;
  align-items: center;
  gap: 10px;
}

.account-tab-btn.active {
  background-color: #0097e6;
  color: white;
}

.dark-mode .account-tab-btn {
  color: #aaa;
}

.dark-mode .account-tab-btn.active {
  background-color: #0097e6;
  color: white;
}

.accounts-section {
  padding: 30px;
}

.account-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(300px, 1fr));
  gap: 20px;
  margin-top: 20px;
}

.account-card {
  background-color: #f9f9f9;
  border-radius: 10px;
  padding: 20px;
  transition: all 0.3s ease;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.05);
}

.dark-mode .account-card {
  background-color: #333;
  box-shadow: 0 5px 15px rgba(0, 0, 0, 0.2);
}

.account-card:hover {
  transform: translateY(-5px);
  box-shadow: 0 8px 25px rgba(0, 0, 0, 0.1);
}

.account-header {
  display: flex;
  align-items: center;
  gap: 15px;
  margin-bottom: 20px;
}

.account-avatar {
  width: 60px;
  height: 60px;
  border-radius: 50%;
  background-color: #eee;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 24px;
  color: #0097e6;
}

.dark-mode .account-avatar {
  background-color: #444;
}

.account-info h4 {
  margin: 0 0 5px;
  font-size: 18px;
  color: #333;
}

.dark-mode .account-info h4 {
  color: #eee;
}

.account-info p {
  margin: 0 0 10px;
  color: #777;
  font-size: 14px;
}

.dark-mode .account-info p {
  color: #aaa;
}

.account-role {
  display: inline-block;
  padding: 3px 10px;
  border-radius: 20px;
  font-size: 12px;
  font-weight: 600;
}

.role-admin, .role-owner {
  background-color: rgba(0, 151, 230, 0.1);
  color: #0097e6;
}

.role-editor {
  background-color: rgba(0, 206, 201, 0.1);
  color: #00cec9;
}

.role-author {
  background-color: rgba(253, 203, 110, 0.1);
  color: #fdcb6e;
}

.account-stats {
  display: flex;
  gap: 15px;
  margin-bottom: 20px;
}

.stat-item {
  flex: 1;
  background-color: #f0f0f0;
  padding: 10px;
  border-radius: 8px;
  text-align: center;
}

.dark-mode .stat-item {
  background-color: #444;
}

.stat-label {
  display: block;
  font-size: 12px;
  color: #777;
  margin-bottom: 5px;
}

.dark-mode .stat-label {
  color: #aaa;
}

.stat-value {
  font-size: 18px;
  font-weight: 600;
  color: #333;
}

.dark-mode .stat-value {
  color: #eee;
}

.account-actions {
  display: flex;
  gap: 10px;
}

.account-action-btn {
  flex: 1;
  padding: 10px;
  border: none;
  border-radius: 5px;
  background-color: #f0f0f0;
  color: #555;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  transition: all 0.3s ease;
}

.dark-mode .account-action-btn {
  background-color: #444;
  color: #ddd;
}

.account-action-btn:hover {
  background-color: #e0e0e0;
}

.dark-mode .account-action-btn:hover {
  background-color: #555;
}

.no-accounts-message {
  text-align: center;
  padding: 40px;
  color: #777;
  font-size: 16px;
}

.dark-mode .no-accounts-message {
  color: #aaa;
}

/* صفحة إنشاء حساب */
.create-account-section {
  padding: 30px;
}

.create-account-section h3 {
  margin-bottom: 25px;
  color: #0097e6;
}

.create-account-form {
  max-width: 700px;
}

.permissions-section {
  margin-top: 25px;
  padding-top: 25px;
  border-top: 1px solid #eee;
}

.dark-mode .permissions-section {
  border-top-color: #444;
}

.permissions-section h4 {
  margin-bottom: 15px;
  color: #333;
}

.dark-mode .permissions-section h4 {
  color: #eee;
}

.permissions-list {
  display: grid;
  grid-template-columns: repeat(auto-fill, minmax(200px, 1fr));
  gap: 15px;
}

.permission-item {
  display: flex;
  align-items: center;
  gap: 10px;
}

.permission-item input {
  width: 18px;
  height: 18px;
  cursor: pointer;
}

.permission-item label {
  cursor: pointer;
  color: #555;
}

.dark-mode .permission-item label {
  color: #ddd;
}
