/* تأثير تساقط النجوم */
.starfall-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 120px;
  overflow: hidden;
  pointer-events: none;
  z-index: 1;
}

/* حاوية النجوم في الأعلى - تم تحويلها لنجوم متحركة */
.static-stars-container {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 35px; /* زيادة الارتفاع ليسمح بنزول النجوم أكثر */
  overflow: hidden;
  pointer-events: none;
  z-index: 2;
}

.star {
  position: absolute;
  width: 15px;
  height: 15px;
  opacity: 0.8;
  filter: drop-shadow(0 0 2px rgba(255, 215, 0, 0.5));
  animation-name: starfall;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 0s !important; /* إلغاء التأخير الأولي */
  background-image: none;
  clip-path: polygon(
    50% 0%, 
    61% 35%, 
    98% 35%, 
    68% 57%, 
    79% 91%, 
    50% 70%, 
    21% 91%, 
    32% 57%, 
    2% 35%, 
    39% 35%
  );
  background: linear-gradient(45deg, #FFC107, #FFEB3B, #FF9800, #FF5722);
  transform-origin: center;
}

/* نجمة في السطر العلوي - أصبحت متحركة */
.small-falling-star {
  position: absolute;
  width: 4px;
  height: 4px;
  filter: drop-shadow(0 0 1px rgba(255, 215, 0, 0.6));
  animation-name: small-starfall;
  animation-timing-function: linear;
  animation-iteration-count: infinite;
  animation-delay: 0s !important; /* إلغاء التأخير الأولي */
  background-image: none;
  clip-path: polygon(
    50% 0%, 
    61% 35%, 
    98% 35%, 
    68% 57%, 
    79% 91%, 
    50% 70%, 
    21% 91%, 
    32% 57%, 
    2% 35%, 
    39% 35%
  );
  background: linear-gradient(45deg, #FFC107, #FFEB3B);
  transform-origin: center;
}

/* نجمة صغيرة جداً */
.small-falling-star-tiny {
  width: 3px;
  height: 3px;
}

/* نجمة صغيرة */
.small-falling-star-small {
  width: 4px;
  height: 4px;
}

/* أحجام مختلفة للنجوم */
.star-small {
  width: 5px;
  height: 5px;
}

.star-medium {
  width: 8px;
  height: 8px;
}

.star-large {
  width: 11px;
  height: 11px;
}

/* حركة النجوم الصغيرة في الأعلى */
@keyframes small-starfall {
  0% {
    transform: translateY(0) rotate(0deg);
    opacity: 0.9;
  }
  100% {
    transform: translateY(35px) rotate(45deg); /* زيادة مسافة الحركة للأسفل */
    opacity: 0.9;
  }
}

/* تأثير تساقط النجوم بحركة ثابتة السرعة وتلاشي سلس */
@keyframes starfall {
  0% {
    transform: translateY(-10px) rotate(0deg);
    opacity: 0;
  }
  10% {
    transform: translateY(5px) rotate(45deg);
    opacity: 0.8;
  }
  60% {
    transform: translateY(70px) rotate(90deg);
    opacity: 0.8;
  }
  80% {
    transform: translateY(90px) rotate(135deg);
    opacity: 0.4;
  }
  90% {
    transform: translateY(110px) rotate(160deg);
    opacity: 0.2;
  }
  100% {
    transform: translateY(120px) rotate(180deg);
    opacity: 0;
  }
}
