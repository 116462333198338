/* الأنماط الأساسية */
@import 'logo.css';

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

body {
  font-family: 'Cairo', sans-serif;
  direction: rtl;
  text-align: right;
}

/* المتغيرات العامة للألوان */
:root {
  /* ألوان الوضع النهاري */
  --primary-light: #0066cc; /* أزرق غامق */
  --primary-light-hover: #0080ff; /* أزرق فاتح */
  --text-on-primary-light: #ffffff; /* أبيض */
  --background-light: #a7a7a7; /* لون رمادي متوسط */
  --text-light: #333333; /* داكن للنصوص */
  
  /* ألوان الوضع الليلي */
  --primary-dark: #00b8b8; /* تركواز غامق */
  --primary-dark-hover: #00d5d5; /* تركواز فاتح */
  --text-on-primary-dark: #ffffff; /* أبيض */
  --background-dark: #1a1a1a; /* داكن جدًا */
  --text-dark: #f0f0f0; /* فاتح للنصوص */
}

/* الوضع النهاري (الافتراضي) */
.light-mode {
  --primary: var(--primary-light);
  --primary-hover: var(--primary-light-hover);
  --text-on-primary: var(--text-on-primary-light);
  --background: var(--background-light);
  --text: var(--text-light);
}

/* الوضع الليلي */
.dark-mode {
  --primary: var(--primary-dark);
  --primary-hover: var(--primary-dark-hover);
  --text-on-primary: var(--text-on-primary-dark);
  --background: var(--background-dark);
  --text: var(--text-dark);
}

/* أنماط عامة */
a {
  text-decoration: none;
  color: var(--primary);
}

button {
  cursor: pointer;
  border: none;
  padding: 8px 16px;
  border-radius: 4px;
  background-color: var(--primary);
  color: var(--text-on-primary);
  font-family: 'Cairo', sans-serif;
  font-weight: 500;
  transition: background-color 0.3s ease;
}

button:hover {
  background-color: var(--primary-hover);
}
