.footer {
  padding: 5px 0 !important;
  margin-top: 0 !important; /* إزالة الهامش العلوي */
  position: relative;
  min-height: 2.3cm !important;
  height: auto !important;
  box-sizing: border-box !important;
  border-top: 1px solid #eee; /* حد خفيف للفصل */
}

.footer-container {
  max-width: 1200px !important;
  margin: 0 auto !important;
  padding: 0 20px !important;
  display: flex;
  justify-content: space-between;
  align-items: center;
  width: 100% !important;
  box-sizing: border-box !important;
}

.footer-left {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 33% !important;
  box-sizing: border-box !important;
}

.footer-links {
  margin-bottom: 5px !important;
  font-size: 14px !important;
  text-align: right;
  position: relative;
  box-sizing: border-box !important;
}

.footer-links-second-row {
  margin-right: 10px !important;
  margin-top: 12px !important; /* زيادة المسافة من 6px إلى 12px لتناسب موضع الخط الجديد */
  position: relative;
  box-sizing: border-box !important;
}

.footer-links:first-child:after {
  content: '';
  position: absolute;
  bottom: -10px !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  width: calc(100% + 1cm) !important;
  height: 1px !important;
  background-color: rgba(0, 0, 0, 0.1);
  box-sizing: border-box !important;
}

.dark-mode .footer-links:first-child:after {
  background-color: rgba(255, 255, 255, 0.1);
}

.footer-links-second-row:before {
  display: none;
}

.footer-links a {
  color: var(--text);
  text-decoration: none;
  transition: color 0.3s;
  font-size: 14px !important;
  box-sizing: border-box !important;
}

.footer-links a:hover {
  color: var(--primary);
}

.footer-center {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 33% !important;
  box-sizing: border-box !important;
}

.footer-logo {
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box !important;
}

.footer-logo-img {
  max-height: 150px !important;
  width: auto !important;
  box-sizing: border-box !important;
}

.footer-right {
  width: 33% !important;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  box-sizing: border-box !important;
}

.copyright {
  font-size: 14px !important;
  color: var(--text);
  text-align: right;
  box-sizing: border-box !important;
}

/* زر العودة لأعلى */
.scroll-to-top-btn {
  position: fixed;
  bottom: 30px;
  right: 30px; /* تغيير من left إلى right ليناسب اتجاه RTL في المدونة العربية */
  width: 40px;
  height: 40px;
  border-radius: 50%;
  background-color: transparent;
  color: #1a91ff;
  border: 2px solid #1a91ff;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  box-shadow: 0 2px 5px rgba(0, 0, 0, 0.2);
  transition: all 0.3s ease;
  z-index: 1000;
  font-size: 18px !important;
  box-sizing: border-box !important;
}

.scroll-to-top-btn:hover {
  background-color: rgba(26, 145, 255, 0.1);
  transform: translateY(-3px);
  box-shadow: 0 0 10px rgba(26, 145, 255, 0.5);
}

.scroll-to-top-btn:focus {
  outline: none;
}

/* وضع ليلي */
.light-mode .footer {
  background-color: #ffffff;
}

.dark-mode .footer {
  background-color: var(--background);
  border-top-color: rgba(255, 255, 255, 0.1);
}

.dark-mode .scroll-to-top-btn {
  background-color: transparent;
  color: #00C3AD;
  border: 2px solid #00C3AD;
}

.dark-mode .scroll-to-top-btn:hover {
  background-color: rgba(0, 195, 173, 0.2);
  box-shadow: 0 0 10px rgba(0, 195, 173, 0.5);
}

/* تخطيط للشاشات الصغيرة */
@media (max-width: 768px) {
  .footer-container {
    flex-direction: column-reverse;
    gap: 20px !important;
    padding: 15px 10px !important;
    box-sizing: border-box !important;
  }
  
  .footer-left, .footer-center, .footer-right {
    width: 100% !important;
    justify-content: center;
    align-items: center;
    margin: 5px 0 !important;
    box-sizing: border-box !important;
  }
  
  .footer-left {
    align-items: center;
  }
  
  .footer-links {
    text-align: center;
  }
  
  .footer-logo-img {
    max-height: 50px !important;
  }
  
  .copyright {
    text-align: center;
  }
}

.main-content {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}

.home-page {
  padding-bottom: 0 !important;
  margin-bottom: 0 !important;
}
